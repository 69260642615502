// extracted by mini-css-extract-plugin
export var arrow = "LoanPredictionResult-module--arrow--f4856";
export var createApplicationButtonContainer = "LoanPredictionResult-module--createApplicationButtonContainer--69ca4";
export var fineprintSection = "LoanPredictionResult-module--fineprintSection--7b94e";
export var headingWrapper = "LoanPredictionResult-module--headingWrapper--364cb";
export var inputDetails = "LoanPredictionResult-module--inputDetails--5f661";
export var leftSegment = "LoanPredictionResult-module--leftSegment--9afb8";
export var likelihood = "LoanPredictionResult-module--likelihood--c95ba";
export var likelihoodContainer = "LoanPredictionResult-module--likelihoodContainer--a5715";
export var mainText = "LoanPredictionResult-module--mainText--253d5";
export var percentage = "LoanPredictionResult-module--percentage--0fd5e";
export var predictionContainer = "LoanPredictionResult-module--predictionContainer--5dc93";
export var rangeSegmentBubble = "LoanPredictionResult-module--rangeSegmentBubble--60f8b";
export var rangeSegmentLabels = "LoanPredictionResult-module--rangeSegmentLabels--db12f";
export var rightSegment = "LoanPredictionResult-module--rightSegment--54d90";
export var securedLoanApplicationSection = "LoanPredictionResult-module--securedLoanApplicationSection--fbaf6";
export var segmentRangesContainer = "LoanPredictionResult-module--segmentRangesContainer--0ca0a";
export var sliderWrapper = "LoanPredictionResult-module--sliderWrapper--01661";
export var threeSegmentsContainer = "LoanPredictionResult-module--threeSegmentsContainer--9634e";
export var twoSegmentsContainer = "LoanPredictionResult-module--twoSegmentsContainer--9e415";
export var wrapper = "LoanPredictionResult-module--wrapper--1b96a";