import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type SortInputProps = {
  options: { value: string, label: string }[];
  defaultOption?: string;
  onChange: (value: string) => void;
  onRenderValue?: (value: string) => string | undefined;
}

const SortInput = ({ options, defaultOption, onRenderValue, onChange }: SortInputProps) => {
  const [sortBy, setSortBy] = React.useState(defaultOption);

  React.useEffect(() => {
    setSortBy(defaultOption);
  }, [defaultOption])

  const handleChange = (event: SelectChangeEvent) => {
    setSortBy(event.target.value);
    onChange(event.target.value);
  };

  const renderValue = React.useCallback((value: string) => {
    if (onRenderValue) {
      return onRenderValue(value);
    }

    if (value == null) {
      return;
    }

    const selectedOption = options.find((option) => option.value === value);
    return `Sortera efter: ${selectedOption.label}`;
  }, [])

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120, display: 'flex', flexDirection: 'row' }}>
        <Select
          value={sortBy}
          onChange={handleChange}
          renderValue={renderValue}
          disableUnderline={true}
          sx={{ color: '#8940F7' }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default SortInput;