import React, { useState } from 'react';
import { LoanApplicationStatus, useLoanBrokerApplicationDetailsQuery, useLoanBrokerApplicationStateQuery } from '../../../../graphql/generated';
import SwitchSelector from "react-switch-selector";
import * as styles from './ApplicationDetails.module.scss';
import { formatAccommodationType, formatChildren, formatMaritalStatus, formatOccupationType, formatPhoneNumber, formatMoney, formatAmortizationPeriod, formatMonth, formatStatus } from '../util';
import InfoBox from '../InfoBox';
import ApplicationStatusPill from '../ApplicationStatusPill';
import Dialog from '../Dialog';
import { useCancelApplicationMutation } from '../../../../graphql/generated';
import { navigate } from 'gatsby-plugin-intl';
import Breadcrumbs from '../Breadcrumbs';
import { RouteComponentProps } from "@reach/router"
import CircularProgress from '@mui/material/CircularProgress';
import * as Options from '@lib/options';

interface Props extends RouteComponentProps {

}

interface ItemProps {
  label: string,
  small?: boolean,
  children: React.ReactNode,
}

function Item({ label, small, children }: ItemProps) {
  return <div className={small ? styles.smallItem : styles.item}>
    <div>{label}</div>
    {children}
  </div>
}

function ApplicationDetails(_: Props) {

  const [applicationDetails] = useLoanBrokerApplicationDetailsQuery({ variables: { includeCompleted: true } });
  const [applicationStateResponse] = useLoanBrokerApplicationStateQuery({
    variables: { id: applicationDetails.data?.loanBrokerApplications[0]?.applicationId },
  });
  const moreQuotesToCome = !applicationStateResponse?.data?.loanBrokerState?.done;

  const [cancelApplicationResp, cancelApplication] = useCancelApplicationMutation();

  const [mainApplicantSelected, setMainApplicantSelected] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const application = applicationDetails.data?.loanBrokerApplications[0];
  const applicationData = application?.applicationData;
  const applicantData = mainApplicantSelected ? application?.applicationData : application?.applicationData?.coApplicantData;

  const canDelete = application?.status && [LoanApplicationStatus.New, LoanApplicationStatus.ApplicationDataAdded, LoanApplicationStatus.RequestForQuoteSent].includes(application?.status);

  const coApplicantSelectorOptions = [
    {
      label: "Om dig",
      value: "mainApplicant",
    },
    {
      label: "Om medsökande",
      value: "coApplicant",
    },
  ];

  return  <>
    <Breadcrumbs segments={[
      { path: '/app', name: 'Hem' },
      { path: '/app/loan-application', name: 'Ansökningsdetaljer' }
    ]} />
    <div className={styles.root}>
      <h2>Din ansökan</h2>
      <div className={styles.panes}>
        {applicationData &&
          <div className={styles.application}>
            <div className={styles.group}>
              <Item label="Status"><ApplicationStatusPill>{formatStatus(application.status, moreQuotesToCome)}</ApplicationStatusPill></Item>
              <Item label="Lån"><div>{`${formatMoney(applicationData.totalAmount)} kr`}</div></Item>
              {applicationData.consolidate &&
                <Item label="Varav lån att samla" small={true}><div>{`${formatMoney(applicationData.consolidate.ofTotalToConsolidate)} kr`}</div></Item>
              }
              <Item label="Återbetalningstid"><div>{formatAmortizationPeriod(applicationData.amortizationPeriod)}</div></Item>
              <Item label="Medsökande"><div>{applicationData.coApplicantData ? "Ja" : "Nej"}</div></Item>
              <Item label="Lånesyfte"><div>{Options.loanPurpose.find(
                      ({ value }) => value === applicationData.loanPurpose,
                    )?.label}</div></Item>
            </div>
          </div>
        }
        {canDelete &&
          <div className={styles.actions}>
            <InfoBox>Avbyter du ansökan tar vi inte in fler erbjudanden och avböjer automatiskt de erbjudanden som kommit in.</InfoBox>
            <button className={styles.deleteButton} onClick={() => setDeleteDialogOpen(true)}>Avbryt ansökan</button>
          </div>
        }
        <div className={styles.applicant}>
          {applicationData?.coApplicantData && <SwitchSelector
            options={coApplicantSelectorOptions}
            onChange={(value) => {
              value === "mainApplicant" ? setMainApplicantSelected(true) : setMainApplicantSelected(false)
            }}
            backgroundColor="#DBD9DE"
            selectedBackgroundColor="#FFFFFF"
            wrapperBorderRadius={16}
            optionBorderRadius={12}
            fontColor="#736E7A"
            selectedFontColor="#2B2654"
            fontSize={16}
            selectionIndicatorMargin={4}
          />}
          {applicantData && <>
            <div className={styles.group}>
              <Item label="E-post"><div>{applicantData.contactDetails.email}</div></Item>
              <Item label="Telefonnummer"><div>{formatPhoneNumber(applicantData.contactDetails.phoneNumber)}</div></Item>
            </div>
            <h3>Familjestatus</h3>
            <div className={styles.group}>
              <Item label="Civilstatus"><div>{formatMaritalStatus(applicantData.personalDetails.maritalStatus)}</div></Item>
              <Item label="Barn"><div>{formatChildren(applicantData.personalDetails.children)}</div></Item>
            </div>
            <h3>Boende</h3>
            <div className={styles.group}>
              <Item label="Boendeform"><div>{formatAccommodationType(applicantData.accommodation.accommodationType)}</div></Item>
              <Item label="Boendekostnaden"><div>{`${formatMoney(applicantData.accommodation.monthlyCost)} kr/mån`}</div></Item>
            </div>
            <h3>Sysselsättning</h3>
            <div className={styles.group}>
              <Item label="Sysselsättning"><div>{formatOccupationType(applicantData.occupation.occupationType.occupation_type_name)}</div></Item>
            </div>
            <h3>Inkomst</h3>
            <div className={styles.group}>
              <Item label="Månadsinkomst"><div>{`${formatMoney(applicantData.occupation.monthlyIncome)} kr/mån`}</div></Item>
              {applicantData.occupation.occupationType.companyName &&
                <Item label="Företagetsnamn"><div>{applicantData.occupation.occupationType.companyName}</div></Item>
              }
              {applicantData.occupation.occupationType.companyPhone &&
                <Item label="Företagets telefon"><div>{formatPhoneNumber(applicantData.occupation.occupationType.companyPhone)}</div></Item>
              }
              <Item label="Anställd sedan"><div>{formatMonth(applicantData.occupation.occupationType.since)}</div></Item>
              {applicantData.occupation.occupationType.to &&
                <Item label="Anställd till"><div>{formatMonth(applicantData.occupation.occupationType.to)}</div></Item>
              }
            </div>
          </>}
        </div>
      </div>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} showClose={true}>
        <div className={styles.confirmation}>
          <h2>Avbryt ansökan?</h2>
          <div>
            Om du avbryter ansökan samlas inga fler låneanbud in. Det är alltid kostnadsfritt att samla in erbjudanden och du behöver inte välja något.
          </div>
          <button className={styles.deleteButton} onClick={() => {
            cancelApplication({ applicationId: application?.applicationId }).then(() =>
              navigate("/app")
            );
          }}>
            <span>Avbryt ansökan</span>
            {cancelApplicationResp.fetching &&
              <CircularProgress size="32px"/>
            }
          </button>
          <a onClick={() => setDeleteDialogOpen(false)}>Gå tillbaka</a>
        </div>
      </Dialog>
    </div>
  </>
}

export default ApplicationDetails;