import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/system/Box';
import React, { useState } from 'react';
import {
  LoanBrokerQuoteConditions,
  PartnerBanks,
  QuoteDecision,
  useLoanBrokerApplicationDetailsQuery,
  useLoanBrokerQuotesQuery,
  useSendQuotesDecisionMutation,
} from '../../../../graphql/generated';
import InfoBox from '../InfoBox';
import {
  bankDisplayInfo,
  formatAmortizationPeriod,
  formatDate,
  formatInterest,
  formatMoney,
  formatMonthlyCost,
} from '../util';
import * as styles from './QuotesOverview.module.scss';
import Breadcrumbs from '../Breadcrumbs';
import { RouteComponentProps } from '@reach/router';
import { Link, navigate } from 'gatsby';
import { LoanApplicationStatus } from '../../../../graphql/generated';
import Dialog from '../Dialog';
import Icon from '@src/components/Icon/Icon';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import useIsMobile from '@src/hooks/useIsMobile.hook';
import Chevron from '../../../images/app/Chevron-Right.svg';
import SortQuotesInput from '../SortQuotesInput';
import Accordion, { Item } from '@src/components/Accordion';
import Markdown from '@src/components/markdown';

// BANKY FAQ
const questions = [
  {
    question: 'Vad är en borgensman?',
    answer:
      'En borgensman är en person som står som säkerhet på lånet och kan betala om du som låntagare av någon anledning inte gör det. En borgensman är i regel en närstående utanför hushållet, t.ex en förälder, ett syskon, ett barn, en kusin eller nära vän. Borgensmannen är betalningsansvarig, men enbart om du som låntagare inte sköter dina åtaganden.',
  },
  {
    question: 'Vem kan vara borgensman?',
    answer:
      'I regel ska borgensmannen ha ordnad ekonomi, vilket innebär att personen ska:\n\n• Var minst 25 år, och högst 75 år vid lånets slut\n• Ha en historik av att allmänt sköta ekonomiska åtaganden, t.ex genom att betala räkningar i tid\n• Har inte tagit ut snabblån\n• Borgensmannen behöver inte äga boende, men det anses positivt i bedömningen om den gör det',
  },
  {
    question:
      'Vad är skillnaden mellan en borgensman och en medlåntagare/medsökande?',
    answer:
      'En borgensman står inte själv med på lånet, utan är enbart en säkerhet om du som låntagare inte fullgör dina skyldigheter. Borgensmannen blir betalningsansvarig endast om låntagaren inte skulle betala. Då får Banky kontakta borgensmannen och dra pengar från hen för Kreditgivarens räkning. Sedan får ni göra upp om kostnaden separat. En medlåntagare har samma skyldigheter som en låntagare under hela låneperioden.',
  },
];

function DetailsRow({ children }) {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '1em',
          backgroundColor: '#ffffff',
        }}
      >
        {children}
      </div>
    </>
  );
}

function ConditionInfoBox(props: {
  bankName: string;
  condition: LoanBrokerQuoteConditions;
}) {
  const { bankName, condition } = props;
  if (
    condition.__typename == 'Complements' &&
    condition.Complements.values.length
  ) {
    return (
      <InfoBox>
        {bankName} kommer att be om kompletterande dokument:
        <ul>
          {condition.Complements.values.map((message, idx) => {
            return <li key={idx}>{message.text}</li>;
          })}
        </ul>
      </InfoBox>
    );
  } else if (condition.__typename == 'ConsolidateDebts') {
    return (
      <InfoBox>
        Du behöver samla minst {condition.ConsolidateDebts.amount.amount} kr för
        att kunna ta detta erbjudande.
      </InfoBox>
    );
  } else if (condition.__typename == 'CoApplicant') {
    return (
      <InfoBox>
        {/* TODO PTDV-229 confirm whats to be displayed here */}
        Medsökande:
        {condition.CoApplicant.message}
        <ul>
          {(condition.CoApplicant.complements || []).map((message, idx) => {
            return <li key={idx}>{message.text}</li>;
          })}
        </ul>
      </InfoBox>
    );
  } else {
    return null;
  }
}

function wrap(content) {
  return (
    <>
      <Breadcrumbs
        segments={[
          { path: '/app', name: 'Hem' },
          { path: '/app/quotes', name: 'Erbjudanden' },
        ]}
      />
      {content}
    </>
  );
}

interface Props extends RouteComponentProps {
  partnerBank?: PartnerBanks;
}

type QuoteSelectionStage =
  | 'init'
  | 'bankAccountInfo'
  | 'bankAccountInput'
  | 'bankAccountConfirm';

function QuotesOverview({ partnerBank }: Props) {
  const [sortBy, setSortBy] = React.useState('time');
  const [activeApplicationResponse] = useLoanBrokerApplicationDetailsQuery({
    variables: { includeCompleted: false },
  });
  const activeApplication =
    activeApplicationResponse.data?.loanBrokerApplications[0];
  const [quotesResponse] = useLoanBrokerQuotesQuery({
    variables: { id: activeApplication?.applicationId },
  });
  const quotes = quotesResponse.data?.loanBrokerQuotes?.quotes || [];

  const [
    quoteDecisionResp,
    sendQuotesDecision,
  ] = useSendQuotesDecisionMutation();
  const [quoteSelectionStage, setQuoteSelectionStage] = useState<
    QuoteSelectionStage | undefined
  >();
  const [accountNumber, setAccountNumber] = useState('');

  const accountNumberValid = /^[0-9]{11,15}$/.test(accountNumber);

  const isMobile = useIsMobile();
  const [bankInfoExpanded, setBankInfoExpanded] = useState(false);

  function goBack() {
    if (quoteSelectionStage) {
      switch (quoteSelectionStage) {
        case 'init':
          setQuoteSelectionStage(undefined);
          break;
        case 'bankAccountInfo':
          setQuoteSelectionStage('init');
          break;
        case 'bankAccountInput':
          setQuoteSelectionStage('bankAccountInfo');
          break;
        case 'bankAccountConfirm':
          setQuoteSelectionStage('bankAccountInput');
          break;
      }
    }
  }

  if (quotes.length == 0) {
    return wrap(
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          height: '6em',
        }}
      >
        <p style={{ fontSize: '0.9em', fontWeight: 400, color: '#736E7A' }}>
          Inga erbjudanden
        </p>
      </div>,
    );
  }
  const selectedQuote = partnerBank
    ? quotes.find(quote => quote.partnerBank === partnerBank)
    : quotes[0];
  const selectedPartnerBank = partnerBank || selectedQuote?.partnerBank;
  const selectedQuoteBankInfo = bankDisplayInfo(selectedPartnerBank);

  const selectedBankInfo = (
    <div style={{ width: '100%' }}>
      {selectedQuoteBankInfo.description.split('\n').map(paragraph => {
        return (
          <p style={{ marginBottom: '1em' }} key={paragraph}>
            {paragraph}
          </p>
        );
      })}
      Läs mer på{' '}
      <a href={selectedQuoteBankInfo.url} rel="noreferrer" target="_blank">
        {selectedQuoteBankInfo.url}
      </a>
      {selectedQuoteBankInfo.name.toLowerCase() === 'banky' && (
        <div style={{ marginTop: '1em', marginBottom: '1em' }}>
          <p style={{ fontSize: '18px', fontWeight: 600, color: '#1E1D20' }}>
            Innan du fortsätter
          </p>
          <p style={{ marginBottom: '1em' }}>
            När du väljer att gå vidare med ett låneerbjudande från Banky vill
            vi göra dig uppmärksam på att de kommer att be dig lägga till en
            borgensman till din låneansökan. Det är ett krav från Banky som
            möjliggör dem att erbjuda krediter till rimliga villkor, utan
            användning av UC, och ibland förmedla krediter även till personer
            som har fått nej från andra långivare. En borgensman är i regel en
            närstående utanför hushållet, t.ex en förälder, ett syskon, ett
            barn, en kusin eller nära vän. Borgensmannen är betalningsansvarig,
            men enbart om du som låntagare inte skulle sköta dina åtaganden.
          </p>
          <Accordion extraSpacing>
            {questions.map(q => (
              <Item key={q.question} title={q.question}>
                <Markdown>{q.answer}</Markdown>
              </Item>
            ))}
          </Accordion>
        </div>
      )}
    </div>
  );

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Breadcrumbs
          segments={[
            { path: '/app', name: 'Hem' },
            { path: '/app/quotes', name: 'Erbjudanden' },
          ]}
        />
        <SortQuotesInput onChange={setSortBy} />
      </Box>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={selectedPartnerBank}
            style={{ background: 'unset' }}
            classes={{
              flexContainer: styles.noBackground,
              indicator: styles.tabIndicator,
            }}
          >
            {quotes
              .sort((a, b) => {
                switch (sortBy) {
                  case 'time':
                    if (a.validTo == null || b.validTo == null) {
                      return 0;
                    }

                    return (
                      new Date(b.validTo).getTime() -
                      new Date(a.validTo).getTime()
                    );
                  case 'amount':
                    return b.amount.amount - a.amount.amount;
                  case 'interest':
                    return a.interestRateNominal - b.interestRateNominal;

                  default:
                    return 0;
                }
              })
              .map(quote => (
                <Tab
                  key={quote.partnerBank}
                  value={quote.partnerBank}
                  label={bankDisplayInfo(quote.partnerBank).name}
                  style={{ background: 'unset' }}
                  component={Link}
                  to={`/app/quotes/${quote.partnerBank}`}
                />
              ))}
          </Tabs>
        </Box>
        {!isMobile && (
          <div
            style={{
              marginTop: '2em',
              marginBottom: '1em',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '1em',
            }}
          >
            <img
              src={selectedQuoteBankInfo.logo}
              alt={`${selectedQuoteBankInfo.name} icon`}
            />
            <div>
              <p style={{ fontSize: '2em', fontWeight: 700 }}>
                {' '}
                {selectedQuoteBankInfo.name}
              </p>
            </div>
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
          {!isMobile && selectedBankInfo}
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '1em',
            }}
          >
            <div className={styles.group}>
              {isMobile && (
                <div
                  style={{
                    padding: '1em',
                    background: '#ffffff',
                    marginTop: '40px',
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                      onClick={() => setBankInfoExpanded(!bankInfoExpanded)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '1em',
                        }}
                      >
                        <img
                          src={selectedQuoteBankInfo.logo}
                          alt={`${selectedQuoteBankInfo.name} icon`}
                        />
                        <div>
                          <p style={{ fontSize: '16px', fontWeight: 500 }}>
                            {' '}
                            {selectedQuoteBankInfo.name}
                          </p>
                        </div>
                      </div>
                      <div>
                        <img
                          width={10}
                          src={Chevron}
                          style={{
                            transform: bankInfoExpanded
                              ? 'rotate(270deg)'
                              : 'rotate(90deg)',
                          }}
                          alt={bankInfoExpanded ? 'collapse' : 'expand'}
                        />
                      </div>
                    </div>
                    {bankInfoExpanded && selectedBankInfo}
                  </div>
                </div>
              )}
              <DetailsRow>
                <p>Godkänt belopp</p>{' '}
                <p>{formatMoney(selectedQuote.amount)} kr</p>
              </DetailsRow>
              <DetailsRow>
                <p>Ränta</p>{' '}
                <p>{formatInterest(selectedQuote.interestRateNominal)}</p>
              </DetailsRow>
              <DetailsRow>
                <p>Effektiv ränta</p>{' '}
                <p>{formatInterest(selectedQuote.interestRateEffective)}</p>
              </DetailsRow>
              <DetailsRow>
                <p>Återbetalningstid</p>{' '}
                <p>
                  {formatAmortizationPeriod(selectedQuote.amortizationPeriod)}
                </p>
              </DetailsRow>
              <DetailsRow>
                <p>Uppläggningsavgift</p>{' '}
                <p>{formatMoney(selectedQuote.setupFee)} kr</p>
              </DetailsRow>
              <DetailsRow>
                <p>Totalt att betala</p>{' '}
                <p>{formatMoney(selectedQuote.totalCost)} kr</p>
              </DetailsRow>
              <DetailsRow>
                <p>Månadskostnad</p>{' '}
                <p>{formatMonthlyCost(selectedQuote.monthlyInstallment)}</p>
              </DetailsRow>
              {selectedQuote.validTo && (
                <DetailsRow>
                  <p>Gäller till</p> <p>{formatDate(selectedQuote.validTo)}</p>
                </DetailsRow>
              )}
            </div>
            {(selectedQuote.conditions || []).map((condition, idx) => {
              return (
                <ConditionInfoBox
                  key={idx}
                  bankName={selectedQuoteBankInfo.name}
                  condition={condition}
                />
              );
            })}
            {activeApplication?.status ===
              LoanApplicationStatus.RequestForQuoteSent && (
              <>
                <button
                  style={{
                    backgroundColor: '#8940F7',
                    color: 'white',
                    borderRadius: '1em',
                    padding: '1em 2em',
                    fontWeight: 500,
                    fontSize: '1em',
                    fontFamily: 'var(--display-font)',
                    cursor: 'pointer',
                  }}
                  onClick={() => setQuoteSelectionStage('init')}
                >
                  Ta det här erbjudandet
                </button>
                <Dialog
                  open={!!quoteSelectionStage}
                  onClose={() => setQuoteSelectionStage(undefined)}
                  showClose={quoteSelectionStage === 'init'}
                  onBack={goBack}
                  showBack={
                    quoteSelectionStage && quoteSelectionStage !== 'init'
                  }
                >
                  <div className={styles.quoteSelection}>
                    {/* STEP 1 */}
                    {quoteSelectionStage === 'init' && (
                      <>
                        <h2>
                          Acceptera erbjudandet från{' '}
                          {bankDisplayInfo(selectedQuote.partnerBank).name}?
                        </h2>
                        <div>
                          {bankDisplayInfo(selectedQuote.partnerBank).name}{' '}
                          kontaktar dig via SMS och/eller e-post för att
                          slutföra din låneansökan. Andra låneerbjudande avvisas
                          automatiskt.
                        </div>
                        <button
                          className={styles.dialogButton}
                          onClick={() => {
                            // TODO decide what to do with Svea and Nstart where account number is optional
                            if (selectedQuote.partnerBank === 'Avida') {
                              setQuoteSelectionStage('bankAccountInfo');
                            } else {
                              sendQuotesDecision({
                                applicationId: activeApplication.applicationId,
                                input: {
                                  decision: QuoteDecision.Accepted,
                                  partnerBank: selectedQuote.partnerBank,
                                },
                              }).then(() => navigate('/app'));
                            }
                          }}
                        >
                          <span>Ta det här erbjudandet</span>
                          {quoteDecisionResp.fetching && (
                            <CircularProgress size="32px" />
                          )}
                        </button>
                        <a onClick={() => setQuoteSelectionStage(undefined)}>
                          Avbryt
                        </a>
                      </>
                    )}
                    {/* STEP 2 */}
                    {quoteSelectionStage === 'bankAccountInfo' && (
                      <>
                        <Icon
                          name="Info"
                          width={72}
                          height={72}
                          className={styles.infoIcon}
                        />
                        <div className={styles.infoText}>
                          {bankDisplayInfo(selectedQuote.partnerBank).name}{' '}
                          behöver ditt bankkonto för utbetalning innan signering
                          kan ske.
                        </div>
                        <button
                          className={styles.dialogButton}
                          onClick={() => {
                            setQuoteSelectionStage('bankAccountInput'); // TODO implement Tink flow here
                          }}
                        >
                          Välj utbetalningskonto
                        </button>
                        <a
                          onClick={() =>
                            setQuoteSelectionStage('bankAccountInput')
                          }
                        >
                          Ange konto manuellt
                        </a>
                      </>
                    )}
                    {/* STEP 3 */}
                    {quoteSelectionStage === 'bankAccountInput' && (
                      <>
                        <h2>Ange utbetalningskonto</h2>
                        <div>
                          Kontot behöver vara ditt eget. Använd gärna ditt
                          löne-/transaktionskonto.
                        </div>
                        <TextField
                          variant="filled"
                          fullWidth
                          value={accountNumber}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setAccountNumber(event.target.value);
                          }}
                          label="Kontonummer"
                          helperText="Ta med clearingnummer"
                          error={!accountNumber && !accountNumberValid}
                          color={
                            accountNumber
                              ? accountNumberValid
                                ? 'success'
                                : 'error'
                              : 'primary'
                          }
                        />
                        <button
                          className={styles.dialogButton}
                          onClick={() => {
                            if (accountNumber && accountNumberValid) {
                              setQuoteSelectionStage('bankAccountConfirm');
                            }
                          }}
                        >
                          Skicka
                        </button>
                      </>
                    )}
                    {/* STEP 4 */}
                    {quoteSelectionStage === 'bankAccountConfirm' && (
                      <>
                        <h2>Korrekt kontonummer?</h2>
                        <div>Bekräfta att {accountNumber} är korrekt.</div>
                        <button
                          className={styles.dialogButton}
                          onClick={() => {
                            sendQuotesDecision({
                              applicationId: activeApplication.applicationId,
                              input: {
                                decision: QuoteDecision.Accepted,
                                partnerBank: selectedQuote.partnerBank,
                                bankAccountNumber: accountNumber,
                              },
                            }).then(() => navigate('/app'));
                          }}
                        >
                          <span>Bekräfta</span>
                          {quoteDecisionResp.fetching && (
                            <CircularProgress size="32px" />
                          )}
                        </button>
                        <a onClick={() => setQuoteSelectionStage(undefined)}>
                          Avbryt
                        </a>
                      </>
                    )}
                  </div>
                </Dialog>
              </>
            )}
          </div>
        </div>
      </Box>
    </Box>
  );
}

export default QuotesOverview;
