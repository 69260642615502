import React from 'react';
import * as styles from './InfoBox.module.scss';
import Icon from '@src/components/Icon/Icon';

interface InfoBoxProps {
  children: React.ReactNode
}

export default function InfoBox({children}: InfoBoxProps) {
  return <div className={styles.infoBox}>
    <Icon name="Info" className={styles.icon}/>
    {children}
  </div>;
}


