import LoanBrokerApplicationFlow from "@src/components/LoanBrokerApplication/LoanBrokerApplicationFlow";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import * as styles from './StartNewApplicationTab.module.scss';
import Icon from "@src/components/Icon/Icon";
import MoneyBag from '../../../images/app/money_bag_icon.svg';

export default function StartNewApplicationTab(props: { isSmallScreen: boolean }) {
  const [formOpen, setFormOpen] = useState(false)

  const startButton = <button className={styles.startButton} onClick={() => setFormOpen(true)}>
    Starta en ansökan
  </button>

  return <>
    <div className={styles.container}>
      <img
        src={MoneyBag}
        className={styles.icon}
        alt="icon"
      />
      <div>
        <p className={styles.text}>Starta en ansökan</p> <p className={styles.smallText}>Låna upp till 600 000 kr</p>
      </div>
      <div className={styles.flex}></div>
      <div className={styles.marginRight}>
        {props.isSmallScreen ?
          <button
            style={{background: 'none'}}
            onClick={() => {
              console.log("asd");
              setFormOpen(true);
            }}>
            <StaticImage
            src="../../../images/app/Big-Add-Button.svg"
            alt="icon"
            style={{ cursor: "pointer" }}
            />
          </button>
           : startButton
        }
      </div>
    </div>
    <div className={styles.background}>
      <ul className={styles.checkList}>
        <li><Icon name="CheckV3" />Låna mellan 10 000 kr - 600 000 kr</li>
        <li><Icon name="CheckV3" />Upp till 20 års återbetalningstid</li>
        <li><Icon name="CheckV3" />Svar från flera banker med endast en UC</li>
        <li><Icon name="CheckV3" />Alltid kostnadsfritt att ansöka</li>
      </ul>
    </div>
    {props.isSmallScreen &&
      <button className={styles.fullWidthButton} onClick={() => setFormOpen(true)}>
        Starta en ansökan
      </button>
    }
    {formOpen &&
      <LoanBrokerApplicationFlow closeModal={() => {
        setFormOpen(false)
      }} submitWithBankId={false} initialStep="Start" />
    }    
  </>
}