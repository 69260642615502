import { Link } from "gatsby-plugin-intl";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { formatAmortizationPeriod, formatMoney, formatDate, formatStatus, bankDisplayInfo } from "../util";
import ApplicationStatusPill from "../ApplicationStatusPill";
import QuoteRow from "./QuoteRow";
import Row from "./Row";
import { LoanApplicationData, LoanApplicationStatus, LoanBrokerApplicationResponse, LoanBrokerQuote, LoanBrokerQuoteDecisionResponse, LoanBrokerQuotesResponse } from "../../../../graphql/generated";
import Recepit from '../../../images/app/receipt_icon.svg';
import Chevron from '../../../images/app/Chevron-Right.svg';
import * as styles from './ActiveApplicationTab.module.scss';
import SortQuotesInput from "../SortQuotesInput";
import { useMediaQuery } from "react-responsive";

type ActiveApplicationProps = {
  applicationData: LoanApplicationData | null;
  createdAt: string;
  status: LoanApplicationStatus;
  moreQuotesToCome: boolean;
  totalQuotes: number;
  selectedPartnerBankName?: string;
}

const ActiveApplication = memo(({ applicationData, createdAt, status, totalQuotes, moreQuotesToCome, selectedPartnerBankName }: ActiveApplicationProps) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });

  if (applicationData == null) {
    return null;
  }

  return (
    <div className={styles.group}>
      <Link to={"/app/loan-application"}>
        <Row className={styles.activeRow}>
          <div className={styles.rowContent}>
            <img
              src={Recepit}
              alt="icon"
            />
            <div>
              <p className={styles.amount}>{applicationData.totalAmount && formatMoney(applicationData.totalAmount)} kr / {applicationData.amortizationPeriod && formatAmortizationPeriod(applicationData.amortizationPeriod)}</p>
              <p className={styles.date}>Inskickad {formatDate(createdAt)}</p>
            </div>
          </div>
          {!isSmallScreen &&
            <>
              <div>
                <ApplicationStatusPill green={LoanApplicationStatus.RequestForQuoteSent && !moreQuotesToCome}>{formatStatus(status, moreQuotesToCome)}</ApplicationStatusPill>
                <p className={styles.statusText}>
                  {status === LoanApplicationStatus.QuoteSelected ?
                    `${selectedPartnerBankName || ""} kontaktar dig via SMS och/eller e-post för att slutföra din låneansökan.`
                    : totalQuotes === 0 ? "Inga erbjudanden tillgängliga ännu"
                      : totalQuotes === 1 && moreQuotesToCome ? "1 erbjudande tillgängligt (väntar på fler)"
                        : totalQuotes === 1 ? "1 erbjudande tillgängligt"
                          : moreQuotesToCome ? `${totalQuotes} erbjudanden tillgängliga (väntar på fler)`
                            : `${totalQuotes} erbjudanden tillgängliga`
                  }
                </p>
              </div>
            </>
          }
          <div className={styles.link}>
            <a className={styles.linkContent}>
              {!isSmallScreen && 'Gå till ansökan'}
              <img
                src={Chevron}
                alt="icon"
              /></a>
          </div>
        </Row>
      </Link>
    </div>
  )
});

ActiveApplication.displayName = 'ActiveApplication';

type QuotesProps = {
  quotes: LoanBrokerQuote[];
  application: LoanBrokerApplicationResponse;
  quoteDecision?: LoanBrokerQuoteDecisionResponse;
  moreQuotesToCome: boolean;
}

const Quotes = memo(({ application, quotes, quoteDecision, moreQuotesToCome }: QuotesProps) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });
  const [sortBy, setSortBy] = React.useState<string>('time');

  const sortQuotes = useCallback((sortByValue: string) => {
    setSortBy(sortByValue);
  }, []);

  const sortedQuotesList = useMemo(() => {
    const sortedQuotes = [...quotes];
    if (sortBy === 'time') {
      sortedQuotes.sort((a, b) => {
        if (a.validTo == null || b.validTo == null) {
          return 0;
        }

        return new Date(b.validTo).getTime() - new Date(a.validTo).getTime();
      });
    }

    // Sort by amount
    if (sortBy === 'amount') {
      sortedQuotes.sort((a, b) => b.amount.amount - a.amount.amount);
    }

    // Sort by interest
    if (sortBy === 'interest') {
      sortedQuotes.sort((a, b) => a.interestRateNominal - b.interestRateNominal);
    }

    return sortedQuotes.map(quote => {
      return <QuoteRow isSmallScreen={isSmallScreen} key={quote.partnerBank} quote={quote} applicationStatus={application.status} isChosen={quoteDecision?.partnerBank == quote.partnerBank} />
    });

  }, [quotes, isSmallScreen, sortBy, application.status, quoteDecision]);

  return (
    <>
      <div className={styles.offersTitle}>
        <h4>Erbjudanden <ApplicationStatusPill green={LoanApplicationStatus.RequestForQuoteSent && !moreQuotesToCome}>{formatStatus(application.status, moreQuotesToCome)}</ApplicationStatusPill></h4>
        <SortQuotesInput onChange={sortQuotes} />
      </div>
      <div>
        {quotes.length > 0 && !moreQuotesToCome && <p>Alla låneerbjudanden från våra partners är inhämtade</p>}
      </div>

      {quotes.length == 0 && <Row className={styles.noOffers}><p>Inga erbjudanden ännu...</p></Row>}
      <div className={`${styles.group} ${styles.groupWithGaps}`}>
        {sortedQuotesList}
      </div>
    </>
  )
});

Quotes.displayName = 'Quotes';

type ActiveApplicationTabProps = {
  application: LoanBrokerApplicationResponse;
  quotes: LoanBrokerQuote[];
  quoteDecision?: LoanBrokerQuoteDecisionResponse;
  moreQuotesToCome: boolean;
}

function areEqual(prevProps: ActiveApplicationTabProps, nextProps: ActiveApplicationTabProps) {
  return prevProps.application.applicationId === nextProps.application.applicationId
    && prevProps.quotes.length === nextProps.quotes.length
    && prevProps.moreQuotesToCome === nextProps.moreQuotesToCome
    && prevProps.quoteDecision?.partnerBank === nextProps.quoteDecision?.partnerBank;
}

const ActiveApplicationTab = ({ application, quotes, quoteDecision, moreQuotesToCome }: ActiveApplicationTabProps) => {
  const [selectedPartnerBankName, setSelectedPartnerBankName] = React.useState<string | null>(null);

  useEffect(() => {
    if (quoteDecision?.partnerBank) {
      setSelectedPartnerBankName(bankDisplayInfo(quoteDecision.partnerBank).name);
    }
  }, [quoteDecision]);

  return <>
    {/* ACTIVE APPLICATION */}
    <ActiveApplication
      applicationData={application.applicationData}
      createdAt={application.createdAt}
      status={application.status}
      totalQuotes={quotes.length}
      moreQuotesToCome={moreQuotesToCome}
      selectedPartnerBankName={selectedPartnerBankName}
    />
    {/* QUOTES */}
    <Quotes
      quotes={quotes}
      application={application}
      quoteDecision={quoteDecision}
      moreQuotesToCome={moreQuotesToCome}
    />
  </>
}

export default memo(ActiveApplicationTab, areEqual);