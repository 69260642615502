

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Download from "../../../images/app/app_download_qr.svg"
import App from "../../../images/app/app_screenshot.svg"
import { playStoreUrl } from '@src/lib/utils';
import * as styles from './DownloadPanel.module.scss';

function DownloadPanel() {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });
  return <>
    <div className={styles.panel}>
      Denna funktion finns idag bara i appen. Ladda ner till din mobil och kom igång på nolltid.
      <a className={styles.downloadButton} href={playStoreUrl}>Ladda&nbsp;ner</a>
    </div>
    <div className={styles.content}>
      <div className={styles.leftColumn}>
        <h2>Gör som 394&nbsp;000 andra</h2>
        <h4>
          {isSmallScreen ?
            "Ladda ner Rocker-appen till din mobil." :
            "Scanna QR-koden för att ladda ner appen."
          }
        </h4>
        {!isSmallScreen &&
          <img
            className={styles.qrCode}
            src={Download}
            alt="App Download QR code"
          />}
      </div>
      <div className={styles.rightColumn}>
        <img
          className={styles.appScreenshot}
          src={App}
          alt="App Screenshot"
        />
      </div>
      {isSmallScreen && <a className={styles.downloadAppButton} href={playStoreUrl}>
          Ladda ner appen
      </a>}
    </div>
  </>
}

export default DownloadPanel;
