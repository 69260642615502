// extracted by mini-css-extract-plugin
export var background = "StartNewApplicationTab-module--background--a6340";
export var buttonContainer = "StartNewApplicationTab-module--buttonContainer--760e9";
export var checkList = "StartNewApplicationTab-module--checkList--25cf2";
export var container = "StartNewApplicationTab-module--container--d7f2a";
export var flex = "StartNewApplicationTab-module--flex--cd6c8";
export var fullWidthButton = "StartNewApplicationTab-module--fullWidthButton--d5c3b";
export var icon = "StartNewApplicationTab-module--icon--7e3cc";
export var startButton = "StartNewApplicationTab-module--startButton--57989";
export var subtitle = "StartNewApplicationTab-module--subtitle--83c0d";
export var title = "StartNewApplicationTab-module--title--99071";