import { Link } from 'gatsby-plugin-intl';
import React from 'react';
import ApplicationStatusPill from '../ApplicationStatusPill';
import Row from './Row';
import Receipt from "../../../images/app/receipt_icon.svg";
import Chevron from "../../../images/app/Chevron-Right.svg";
import * as styles from './ApplicationsOverview.module.scss';
import InfoBox from '../InfoBox';
import useIsMobile from '@src/hooks/useIsMobile.hook';
import { formatDate } from '../util';

export function DeclinedApplicationTab(props: { application: any }) {
  const isMobile = useIsMobile();

  return <>
    <div className={styles.group} style={{marginTop: '24px'}}>
      <InfoBox>
        Tyvärr har ingen av våra partners möjlighet att erbjuda dig ett lån just nu. Återkom med en ny ansökan vid senare tillfälle.
      </InfoBox>
    </div>
    <div className={styles.group} style={{marginTop: '24px'}}>
      <Link to="/app/loan-application">
        <Row style={{ color: "#1E1D20", height: 'unset', padding: '1em' }}>
          <div style={{ marginRight: '1em' }}>
            <img
              src={Receipt}
              alt="icon"
            />
          </div>
          <div style={{ width: '40%' }}>
            <p style={{ fontWeight: 500 }}>300 000 kr / 8 år</p>
            <p style={{ fontSize: '0.9em', fontWeight: 400, color: "#736E7A" }}>Inskickad {props.application?.createdAt && formatDate(props.application?.createdAt)}</p>
          </div>
          {!isMobile && <div style={{ width: '40%' }}>
            <ApplicationStatusPill>INGA ERBJUDANDEN </ApplicationStatusPill>
            <p style={{ fontSize: '0.9em', fontWeight: 400, color: "#736E7A" }}>
              Tyvärr har ingen av våra partners möjlighet att erbjuda dig ett lån just nu. Återkom med en ny ansökan vid senare tillfälle.
            </p>
          </div>}
          <div style={{ flex: 1 }}></div>
          <div style={{ marginRight: '1em' }}>
            <div style={{ display: 'flex', alignItems: 'center', color: '#8940F7', gap: '0.5em' }}>
              {!isMobile && <p>Gå till ansökan</p>}
              <img
                src={Chevron}
                alt="icon"
              />
            </div>
          </div>
        </Row>
      </Link>
    </div>
  </>
}