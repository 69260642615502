import classNames from 'classnames';
import React, { forwardRef, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import * as styles from './TextInput.module.scss';

export type InputStatus = 'default' | 'success' | 'error';

export type TextInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  className?: string;
  theme?: string;
  status?: InputStatus;
  onChange?: React.FormEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  labelHidden?: boolean;
  label?: string;
  subLabel?: string;
  errorLabel?: string;
  successLabel?: string;
  isDirty?: boolean;
  performValidation?: (_) => boolean;
  id?: string;
  wrapperStyle?: React.CSSProperties;
};

function _TextInput(
  {
    className,
    onChange,
    onBlur,
    status,
    errorLabel,
    successLabel,
    subLabel,
    label,
    labelHidden,
    id,
    isDirty,
    theme,
    performValidation,
    wrapperStyle,
    ...restProps
  }: TextInputProps,
  ref,
) {
  const [_dirty, setDirty] = useState(false);

  const _onChange = e => {
    performValidation?.(e.target.value);
    onChange?.(e);
  };

  const _onBlur = e => {
    setDirty(true);
    performValidation?.(e.target.value);
    onBlur?.(e);
  };

  const dirty = _dirty || isDirty;

  const classes = classNames({
    [styles.input]: true,
    [styles.success]: status === 'success' && dirty,
    [styles.error]: status === 'error' && dirty,
    [className]: className,
  });

  const inputId = useRef(uuidv4());

  const wrapperClasses = classNames({
    [styles.wrapper]: true,
    [styles.dark]: theme === 'dark',
    [styles.error]: status === 'error' && dirty && errorLabel,
    [styles.success]: status === 'success' && dirty && successLabel,
    [styles.noSubLabel]: !errorLabel && !successLabel,
    [styles.labelHidden]: labelHidden,
  });

  return (
    <div className={wrapperClasses} style={wrapperStyle}>
      {label && (
        <label className={styles.topLabel} htmlFor={id || inputId.current}>
          {label}
        </label>
      )}
      <input
        id={id || inputId.current}
        ref={ref}
        className={classes}
        onChange={_onChange}
        onBlur={_onBlur}
        aria-invalid={status === 'error'}
        aria-errormessage={
          status === 'error' && errorLabel ? errorLabel : undefined
        }
        {...restProps}
      />
      {subLabel && (
        <div className={classNames([styles.subLabel, styles.defaultLabel])}>
          {subLabel}
        </div>
      )}
      {errorLabel && (
        <div className={classNames([styles.subLabel, styles.errorLabel])}>
          {errorLabel}
        </div>
      )}
      {successLabel && (
        <div className={classNames([styles.subLabel, styles.successLabel])}>
          {successLabel}
        </div>
      )}
    </div>
  );
}

export default forwardRef<HTMLInputElement, TextInputProps>(_TextInput);
