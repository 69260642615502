import React, { useEffect, useState } from 'react';
import PrimaryButton from '../../PrimaryButton';
import appSlice from '@src/redux/app';
import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import * as styles from './LoginForm.module.scss';
import Logo from '@src/components/Logo';
import Login1 from '../../../icons/app/Login1.svg';
import { StaticImage } from 'gatsby-plugin-image';
import {
  AuthStatus,
  useGetAuthStatusQuery,
  useInitAuthMutation,
} from '../../../../graphql/generated';
import QRCode from 'react-qr-code';
import { Link } from 'gatsby';
import InfoBox from '../InfoBox';
import Arrow from '../../icons/arrow';
import useIsMobile from '@src/hooks/useIsMobile.hook';
import { isIOS } from 'react-device-detect';
import { playStoreUrl } from '@src/lib/utils';

interface Props {
  setLoggedId: () => void;
}

type LoginState = 'not_started' | 'started' | 'started_mobile' | 'canceled';

function LoginForm({ setLoggedId }: Props) {
  const isMobile = useIsMobile();

  const [loginState, setLoginState] = useState<LoginState>('not_started');

  const [initAuthResult, initAuth] = useInitAuthMutation();
  const [authStatus, getAuthStatus] = useGetAuthStatusQuery({
    variables: { authJwt: initAuthResult.data?.postAuth.authJwt },
    pause: true,
  });

  const [bankIdAppStarted, setBankIdAppStarted] = useState(false);

  const isInstagramOnIOS =
    typeof window !== 'undefined' &&
    window.navigator.userAgent.toLowerCase().includes('instagram') &&
    isIOS;

  useEffect(() => {
    if (
      (loginState !== 'started' && loginState !== 'started_mobile') ||
      !initAuthResult.data ||
      authStatus.fetching
    ) {
      return;
    }

    if (
      loginState === 'started_mobile' &&
      !bankIdAppStarted &&
      initAuthResult.data?.postAuth.launchInfo.autoStartToken
    ) {
      setBankIdAppStarted(true);
      let redirect = 'null';
      if (isIOS) {
        // Hack alert!
        // In order to prevent Safari from reloading the page when redirecting from BankId the nonexisting anchor is added to the redirect link.
        // The hack was found on SO: https://stackoverflow.com/a/24786670/488035
        redirect = encodeURIComponent(`${window.location.href}#bankId`);
      }
      const query = `?autostarttoken=${initAuthResult.data?.postAuth.launchInfo.autoStartToken}&redirect=${redirect}`;
      const bankIdUrl = `bankid:///${query}`;
      window.location.replace(bankIdUrl);
    }

    const timerId = setTimeout(() => {
      getAuthStatus({ requestPolicy: 'network-only' });
    }, 1000);

    return () => clearTimeout(timerId);
  }, [initAuthResult.data, authStatus.fetching, getAuthStatus]);

  useEffect(() => {
    const response = authStatus.data?.getAuth;
    if (
      response &&
      response.status == AuthStatus.Success &&
      response.rockerJwt
    ) {
      localStorage.setItem('token', response.rockerJwt);
      setLoggedId();
    }
  }, [authStatus.data]);

  useEffect(() => {
    if (
      initAuthResult.error ||
      authStatus.error ||
      authStatus.data?.getAuth.status === AuthStatus.Failure
    ) {
      setLoginState('canceled'); // TODO handle errors better when we have design for it
    }
  }, [initAuthResult.error, authStatus.error, authStatus.data]);

  const qrAuthData =
    authStatus.data?.getAuth.qrAuthData ||
    initAuthResult.data?.postAuth.launchInfo.qrAuthData;

  const startAuth = () => {
    setLoginState('started');
    initAuth({});
  };

  const startMobileAuth = () => {
    setLoginState('started_mobile');
    initAuth({});
  };

  return (
    <div className={styles.root}>
      {!(
        isMobile &&
        (loginState === 'not_started' || loginState === 'started_mobile')
      ) && (
        <div className={styles.side}>
          {loginState === 'not_started' ? (
            <>
              <Logo height={28} />
              <Login1 />
            </>
          ) : (
            <>
              {!isMobile && (
                <StaticImage
                  src="../../../images/lan/CardAndPhone.png"
                  alt="Gather loans"
                />
              )}
              <h2>Så här gör du:</h2>
              <ol>
                <li>Starta BankID-appen</li>
                <li>Tryck på QR-ikonen i BankID-appen på din mobiltelefon</li>
                <li>
                  Rikta mobiltelefonen mot denna sida och skanna QR-koden som
                  visas här intill
                </li>
                <li>Skriv in din säkerhetskod och tryck “Legitimera”</li>
              </ol>
            </>
          )}
        </div>
      )}
      <div className={styles.main}>
        <div className={styles.header}>
          <Link to="/">
            <Arrow color="black" orientation="left" />
          </Link>
          <Link to="/hjalp">
            <Arrow orientation="left" />
            Hjälp?
          </Link>
        </div>
        {(loginState === 'not_started' || loginState === 'started_mobile') && (
          <div className={styles.contentWide}>
            <h2>Logga in</h2>
            <InfoBox>
              {isMobile
                ? 'Här hittar du dina låneerbjudanden. Försöker du logga in i appen?'
                : 'Här kan du följa din låneansökan, se och jämföra erbjudanden och välja det erbjudande som passar dig bäst. Vill du hellre logga in i appen?'}
              <a href={playStoreUrl}> Klicka här istället.</a>
            </InfoBox>
            <div>Välj inloggningssätt:</div>
            {isMobile && !isInstagramOnIOS ? (
              <>
                <PrimaryButton icon="BankId" onClick={startMobileAuth}>
                  BankID på denna enhet
                </PrimaryButton>
                <button className={styles.secondaryButton} onClick={startAuth}>
                  BankID på annan enhet
                </button>
              </>
            ) : (
              <PrimaryButton icon="BankId" onClick={startAuth}>
                Mobilt BankID
              </PrimaryButton>
            )}
          </div>
        )}
        {loginState === 'started' && (
          <div className={styles.content}>
            <h2>Logga in med Mobilt BankID</h2>
            {qrAuthData && (
              <>
                <QRCode value={qrAuthData} />
              </>
            )}

            {isInstagramOnIOS && (
              <a
                className={styles.secondaryButton}
                href={`https://app.bankid.com/?autostarttoken=${initAuthResult.data?.postAuth.launchInfo.autoStartToken}&redirect=`}
              >
                Öppna BankID på denna enhet
              </a>
            )}
            <button
              onClick={() => setLoginState('canceled')}
              className={styles.secondaryButton}
            >
              Avbryt inloggning
            </button>
          </div>
        )}
        {loginState === 'canceled' && (
          <div className={styles.content}>
            <InfoBox>
              Inloggningen misslyckades eller avbröts. Var vänlig försök igen.
            </InfoBox>
            <PrimaryButton onClick={startAuth}>Försök igen</PrimaryButton>
            <Link to="/" className={styles.centered}>
              Gå tillbaka till rocker.com
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setLoggedId: () => dispatch(appSlice.actions.setLoggedIn(true)),
  };
};

export default connect(null, mapDispatchToProps)(LoginForm);
