import React, { useEffect, useState } from 'react';
import ReactSlider, { ReactSliderProps } from 'react-slider';

import * as styles from './SliderInput.module.scss';
import TextInput from '@components/TextInput/TextInput';

type SliderInputProps = ReactSliderProps & {
  min?: number;
  max?: number;
  value: number;
  suffix?: string;
  label?: string;
  formatValue?: (_: number) => string;
  onChange?: (_: number) => void;
  onAfterChange?: ((value: number, index: number) => void) | undefined;
  id?: string;
  readOnlyTextInput?: boolean;
  showMinMaxLabels?: boolean;
  classNameWrapper?: string;
};

function SliderInput({
  min,
  max,
  value,
  label,
  formatValue,
  onChange,
  onAfterChange,
  readOnlyTextInput,
  id,
  showMinMaxLabels,
  classNameWrapper,
  ...rest
}: SliderInputProps) {
  const [textFocused, setTextFocused] = useState(false);
  const [textInput, setTextInput] = useState('');

  const format = formatValue || (s => `${s}`);

  const onTextFocus = () => {
    if (readOnlyTextInput) return;
    setTextInput(`${value}`);
    setTextFocused(true);
  };

  const onTextBlur = () => {
    if (readOnlyTextInput) return;
    finishTextInput();
    setTextFocused(false);
  };

  const finishTextInput = () => {
    let res = parseInt(textInput);
    if (isNaN(res)) res = value;
    res = Math.max(min, Math.min(max, res));
    if (res !== value) {
      onChange?.(res);
    }
  };

  let textVal = `${value}`;
  if (formatValue) {
    textVal = formatValue(value);
  }
  if (textFocused) {
    textVal = textInput;
  }

  const onTextChange = e => {
    setTextInput(e.target.value);
  };

  const onTextKeyDown = e => {
    if (e.code === 'Enter') {
      e.preventDefault();
      const form = e.target.form;
      const index = [...form].indexOf(e.target);
      if (form.elements[index + 1]) {
        form.elements[index + 1]?.focus();
      } else {
        e.target.blur();
      }
    }
  };

  useEffect(() => {
    if (value < min) {
      onChange?.(min);
    } else if (value > max) {
      onChange?.(max);
    }
  }, [min, max]);

  return (
    <div
      className={[styles.wrapper, classNameWrapper].filter(Boolean).join(' ')}
    >
      <TextInput
        id={id}
        type={textFocused ? 'number' : 'text'}
        onFocus={onTextFocus}
        onBlur={onTextBlur}
        onKeyDown={onTextKeyDown}
        onChange={onTextChange}
        readOnly={readOnlyTextInput}
        label={label}
        value={textVal}
        min={min}
        max={max}
        wrapperStyle={{
          paddingBottom: 0,
        }}
      />
      <ReactSlider
        className={styles.slider}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        onAfterChange={onAfterChange}
        {...rest}
      />
      {showMinMaxLabels && (
        <div className={styles.minMax}>
          <span>{format(min)}</span>
          <span>{format(max)}</span>
        </div>
      )}
    </div>
  );
}

export default SliderInput;
