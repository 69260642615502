// extracted by mini-css-extract-plugin
export var amortizationPeriod = "QuoteRow-module--amortizationPeriod--bd150";
export var amount = "QuoteRow-module--amount--5b25e";
export var bankName = "QuoteRow-module--bankName--6b419";
export var flex1 = "QuoteRow-module--flex1--5a291";
export var flexContainer = "QuoteRow-module--flexContainer--5f2b6";
export var group = "QuoteRow-module--group--e0edd";
export var iconContainer = "QuoteRow-module--iconContainer--2e748";
export var interestRate = "QuoteRow-module--interestRate--bd8f0";
export var label = "QuoteRow-module--label--c0678";
export var monthlyCost = "QuoteRow-module--monthlyCost--ca504";
export var noBackground = "QuoteRow-module--noBackground--9b065";
export var quoteRow = "QuoteRow-module--quoteRow--625b8";
export var row = "QuoteRow-module--row--fb93b";
export var rowPadding = "QuoteRow-module--rowPadding--9616c";
export var rowText = "QuoteRow-module--rowText--9deea";
export var rowValue = "QuoteRow-module--rowValue--50b2f";
export var tabIndicator = "QuoteRow-module--tabIndicator--53142";