// extracted by mini-css-extract-plugin
export var activeRow = "ActiveApplicationTab-module--activeRow--f4639";
export var amount = "ActiveApplicationTab-module--amount--3b758";
export var date = "ActiveApplicationTab-module--date--468ea";
export var group = "ActiveApplicationTab-module--group--9bf52";
export var groupWithGaps = "ActiveApplicationTab-module--groupWithGaps--25663";
export var link = "ActiveApplicationTab-module--link--f7a75";
export var linkContent = "ActiveApplicationTab-module--linkContent--5d556";
export var noBackground = "ActiveApplicationTab-module--noBackground--93ec2";
export var noOffers = "ActiveApplicationTab-module--noOffers--44c0c";
export var offersTitle = "ActiveApplicationTab-module--offersTitle--a828d";
export var row = "ActiveApplicationTab-module--row--bd40f";
export var rowContent = "ActiveApplicationTab-module--rowContent--f7db0";
export var statusText = "ActiveApplicationTab-module--statusText--b3e7e";
export var tabIndicator = "ActiveApplicationTab-module--tabIndicator--3b39f";