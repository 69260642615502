// extracted by mini-css-extract-plugin
export var bankInfo = "DisbursedLoansTab-module--bankInfo--6aa22";
export var bankName = "DisbursedLoansTab-module--bankName--0ecf2";
export var emptyState = "DisbursedLoansTab-module--emptyState--b8b17";
export var emptyStateText = "DisbursedLoansTab-module--emptyStateText--50043";
export var group = "DisbursedLoansTab-module--group--e7af1";
export var linkIcon = "DisbursedLoansTab-module--linkIcon--df6f8";
export var loanDetails = "DisbursedLoansTab-module--loanDetails--de53b";
export var loanRow = "DisbursedLoansTab-module--loanRow--16433";
export var loanStatus = "DisbursedLoansTab-module--loanStatus--f2015";
export var noBackground = "DisbursedLoansTab-module--noBackground--9e9d4";
export var pill = "DisbursedLoansTab-module--pill--a2787";
export var row = "DisbursedLoansTab-module--row--197d8";
export var tabIndicator = "DisbursedLoansTab-module--tabIndicator--684fa";
export var updatedAt = "DisbursedLoansTab-module--updatedAt--4ac58";