import SortInput from '@src/components/SortInput';
import React, { useCallback } from 'react';

const sortOptions = [
    { value: 'time', label: 'Tid (senaste först)' },
    { value: 'amount', label: 'Godkänt belopp (högsta först)' },
    { value: 'interest', label: 'Ränta (lägst först)' },
];

type SortQuotesInputProps = {
    onChange: (sortBy: string) => void;
}

const SortQuotesInput = ({ onChange }: SortQuotesInputProps) => {
    const displaySortOption = useCallback((value: string) => {
        const selectedOption = sortOptions.find((option) => option.value === value).value;

        switch (selectedOption) {
            case 'time':
                return 'Sortera efter: tid';
            case 'amount':
                return 'Sortera efter: belopp';
            case 'interest':
                return 'Sortera efter: ränta';
            default:
                return;
        }
    }, []);

    return (
        <SortInput options={sortOptions} defaultOption={sortOptions[0].value} onChange={onChange} onRenderValue={displaySortOption} />
    );
}

export default SortQuotesInput;