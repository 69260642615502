import React, { useEffect, useState } from 'react';
import { useFetchMemberQuery } from '../../../../graphql/generated';
import * as styles from './Greeting.module.scss';
import { Dispatch } from 'redux';
import appSlice, { AppState } from '@src/redux/app';
import { connect } from 'react-redux';

interface Props {
  customMessage?: string;
  savedName: string;
  setSavedName: (name: string) => void;
  setMemberId: (memberId: string) => void;
}

function Greeting({
  customMessage,
  savedName,
  setSavedName,
  setMemberId,
}: Props) {
  const [token, setToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
    }
  }, []);

  const [member, fetchMember] = useFetchMemberQuery({
    variables: { token: token },
    pause: true,
  });

  useEffect(() => {
    if (token && !member.data?.fetchMember?.payload?.user) {
      console.log(member);
      fetchMember();
    }
  }, [token]);

  useEffect(() => {
    const user = member.data?.fetchMember?.payload?.user;
    const userName = user?.preferredName || user?.firstName;
    if (userName) {
      setSavedName(userName);
    }
    if (user?.id) {
      setMemberId(user.id);
    }
  }, [member.data?.fetchMember?.payload?.user]);

  const user = member.data?.fetchMember?.payload?.user;
  const userName = user?.preferredName || user?.firstName || savedName;

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions;
  const message =
    customMessage || new Date().toLocaleDateString('sv-SE', dateOptions);

  return (
    <div className={styles.greeting}>
      <h1>
        Hej {userName}
        {customMessage && ','}
      </h1>
      <div className={styles.message}>{message}</div>
    </div>
  );
}

const mapStateToProps = (state: { app: AppState }) => ({
  savedName: state.app.firstName,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setSavedName: (name: string) =>
      dispatch(appSlice.actions.setFirstName(name)),
    setMemberId: (memberId: string) =>
      dispatch(appSlice.actions.setMemberId(memberId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Greeting);
