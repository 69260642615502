
import { Link } from 'gatsby';
import React, { CSSProperties, ReactElement } from 'react';
import Arrow from '../../icons/arrow'
import * as styles from './SmallCard.module.scss'
import Icon from '@src/components/Icon/Icon';

interface Props {
  icon: ReactElement,
  title: string,
  text: string,
  target: string,
  style?: CSSProperties | undefined
}

export default function SmallCard({ icon, title, text, target, ...other }: Props) {
  return <Link to={target} className={styles.card} {...other}>
    <div className={styles.cardBody}>
      {icon}
      <div>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
      <Icon name="CircledArrow" width={40} height={40}/>
    </div>
  </Link>
}