import { format, parseNumber } from 'libphonenumber-js';
import { AccomodationType, AmortizationPeriod, LoanApplicationStatus, Children, MaritalStatus, MoneyMinor, OccupationTypeName, MonthlyInstallment } from '../../../graphql/generated';
import * as Options from '@lib/options';
import { formatMoney as formatMoneyInt, formatMoneyText } from '@src/lib/utils';
import AvidaLogo from '../../images/app/bank_logos/Avida.svg';
import BankyLogo from '../../images/app/bank_logos/Banky.svg';
import BnkParibasLogo from '../../images/app/bank_logos/BnpParibas.svg';
import MarginalenLogo from '../../images/app/bank_logos/Marginalen.svg';
import NordaxLogo from '../../images/app/bank_logos/Nordax.svg';
import NorthmillLogo from '../../images/app/bank_logos/Northmill.svg';
import NstartLogo from '../../images/app/bank_logos/Nstart.svg';
import SveaLogo from '../../images/app/bank_logos/Svea.svg';
import CoopLogo from '../../images/app/bank_logos/Coop.svg';
import BankNorwegianLogo from '../../images/app/bank_logos/BankNorwegian.svg';

export function bankDisplayInfo(partnerBank: string) {
  switch (partnerBank) {
    case 'BnpParibas':
      return { name: 'SevenDay', logo: BnkParibasLogo, url: "https://www.sevenday.se/", description: "SevenDay Bank erbjuder attraktiva och prisvärda spar- och låneprodukter till konsumenter. De är specialister på sparande och privatlån, med starkt fokus på att erbjuda enkla villkor och konkurrenskraftiga räntor. SevenDay Bank har dessutom en prisgaranti på privatlån, vilket innebär att de matchar erbjudanden från andra långivare om du skulle hitta ett bättre totalpris. De är en del av BNP Paribas-koncernen och omfattas av den statliga insättningsgarantin, vilket ger dig trygghet i ditt sparande och låntagande. Med SevenDay Bank kan du förvänta dig en personlig och enkel hantering, allt för att göra dig som kund nöjd." }
    case 'SevenDay':
      return { name: 'SevenDay', logo: BnkParibasLogo, url: "https://www.sevenday.se/", description: "SevenDay Bank erbjuder attraktiva och prisvärda spar- och låneprodukter till konsumenter. De är specialister på sparande och privatlån, med starkt fokus på att erbjuda enkla villkor och konkurrenskraftiga räntor. SevenDay Bank har dessutom en prisgaranti på privatlån, vilket innebär att de matchar erbjudanden från andra långivare om du skulle hitta ett bättre totalpris. De är en del av BNP Paribas-koncernen och omfattas av den statliga insättningsgarantin, vilket ger dig trygghet i ditt sparande och låntagande. Med SevenDay Bank kan du förvänta dig en personlig och enkel hantering, allt för att göra dig som kund nöjd." }
    case 'Avida':
      return { name: 'Avida\u00A0Finans', logo: AvidaLogo, url: "https://www.avidafinance.com/sv/start/", description: `Avida Finans är ett kreditmarknadsbolag som grundades 1983 och erbjuder finansiella lösningar och tjänster för privatpersoner och företag i Sverige, Norge och Finland. De står under tillsyn av Finansinspektionen och omfattas av den statliga insättningsgarantin. Avida arbetar för att skapa nöjda kunder genom enkla och moderna lösningar, innovativt tänkande och tekniskt kunnande.\nFör privatpersoner erbjuder Avida möjligheten att samla lån och krediter för att sänka månadskostnaden och få bättre överblick över ekonomin. Deras privatlån kan vara upp till 500 000 kr utan säkerhet i upp till 15 år, med möjlighet till extra amorteringar eller förtida återbetalning. Avida ger en individuell ränta baserad på kundens ekonomiska förutsättningar.` }
    case 'Marginalen':
      return { name: 'Marginalen\u00A0Bank', logo: MarginalenLogo, url: "https://www.marginalen.se/privat/", description: `Marginalen Bank är en svensk bank som brinner för att se människor och företag växa. Sedan grundandet på 90-talet har de fokuserat på att förbättra marginalerna för sina kunder och erbjuda konkurrenskraftiga sparkonton, privatlån och omstartslån för en ekonomisk nystart. Med cirka 300 000 kunder och 300 anställda i Sverige är Marginalen Bank en trygg och säker aktör, under tillsyn av Finansinspektionen och omfattas av insättningsgarantin.\nMarginalen Bank erbjuder även företagare finansiella och administrativa tjänster, med syfte att underlätta tillväxt och frigöra tid för företagaren. Genom att lyssna på sina kunder och erbjuda okomplicerade och konkurrenskraftiga produkter och tjänster, strävar de efter att förenkla den ekonomiska vardagen och skapa möjligheter för både människor och företag att utvecklas.` }
    case 'Svea':
      return { name: 'Svea\u00A0Bank', logo: SveaLogo, url: "https://www.svea.com/se/sv/start/", description: `Svea Bank är en del av Svea-koncernen, som är verksam inom finansbranschen i flera europeiska länder. Med över 40 års erfarenhet och över 2 000 anställda runt om i Europa är Svea Bank en ledande finansiell aktör med en vision att stärka sin position i Europa ytterligare.\nSvea Bank erbjuder finansiella lösningar för både företag och privatpersoner. För privatpersoner fokuserar de på att erbjuda lån och sparande. De har privatlån som är anpassade efter dina behov, oavsett om du har fast anställning eller inte, och tar hänsyn till din återbetalningsförmåga snarare än anställningsform. Svea Bank gör en individuell bedömning för att hitta rätt lån för dig, även om du har en betalningsanmärkning. Om du har flera dyra smålån och krediter kan Svea Bank hjälpa dig att samla dem i ett lån för att spara tid och pengar. Svea Banks engagemang, kompetens och handlingskraft gör dem till en pålitlig partner för privatpersoner som söker finansiella lösningar.` }
    case 'Nordax':
      return { name: 'Nordax\u00A0Bank', logo: NordaxLogo, url: "https://www.nordax.se/", description: `Nordax Bank är ett varumärke inom NOBA Bank Group AB som är en ledande nordisk specialistbank med fokus på finansiell inkludering och ansvarsfull kreditgivning. Nordax Bank grundades 2003 och erbjuder sina över 300 000 kunder i huvudsakligen Sverige, Norge och Finland expertis inom bolån, equity release (seniorlån) och privatlån utan säkerhet.\nNordax Bank anpassar sina produkter och kundmöten efter samtidens och framtidens sätt att leva och är experter på individuell kreditbedömning. De erbjuder hållbara lån för kunder som nekats bolån av storbanker och ger 60-plussare möjligheten att frigöra kapital från sina bostäder genom hypotekspensioner. Nordax Banks låneverksamhet strävar efter att skapa mervärde för sina kunder genom att erbjuda skräddarsydda lösningar och samarbeta med andra kreditförmedlare och företag.\nGenom att välja Nordax Bank som din långivare kan du känna dig trygg, då de står under tillsyn av Finansinspektionen och värnar om en ansvarsfull och inkluderande kreditgivning.` }
    case 'Northmill':
      return { name: 'Northmill', logo: NorthmillLogo, url: "https://www.northmill.com/se/", description: `Northmill Bank är ett svenskt fintech-bolag med banklicens som strävar efter att förbättra kundernas ekonomi. Sedan 2006 har de hjälpt över 2 500 företag och 600 000 privatpersoner genom att erbjuda ekonomiska helhetslösningar. De erbjuder lån, sparkonton och möjligheten att samla lån för att sänka räntan. Dessutom tillhandahåller Northmill Bank kort och konton samt försäkringstjänster. För företagare erbjuder de kassasystem, betalmetoder och integrationer för att anpassa sig efter varje verksamhet.` }
    case 'Nstart':
      return { name: 'Nstart', logo: NstartLogo, url: "https://se.nstart.com/", description: `Nstart är en finansiell aktör som sedan 2016 har hjälpt människor att ta kontroll över sin ekonomi och arbeta mot skuldfrihet. De erbjuder lån för att konsolidera befintliga skulder, vilket innebär att pengarna från lånet enbart används för att betala av gamla lån, skulder och krediter. Nstart samarbetar endast med kunder som visar stark vilja att förändras och en längtan efter en bättre ekonomisk situation. De erbjuder en hållbar privatekonomisk lösning och samlad kunskap med målet att alla deras kunder ska uppnå en hållbar privatekonomi och skuldfrihet.` }
    case 'Banky':
      return { name: 'Banky', logo: BankyLogo, url: "https://banky.se/", description: `Banky är ett kreditinstitut som syftar till att erbjuda finansiella tjänster med schyssta villkor, baserat på faktiskt privatekonomiskt beteende snarare än kredithistorik och föråldrad riskmodellering. Genom att noggrant och automatiskt analysera kundernas ekonomi samt alltid använda en borgensman som extra säkerhet, kan Banky erbjuda lån med bättre villkor. Banky samarbetar med Nordiska kreditmarknadsaktiebolaget och satsar på personlig service, vilket innebär att du alltid kan nå dem via e-post eller telefon inom 24 arbetstimmar. Banky har tillstånd från Finansinspektionen att bedriva förmedling av konsumentkrediter och försäkringsförmedling, vilket innebär att de kan förmedla lån och försäkringar till dig.` }
    case 'Coop':
      return { name: 'Coop\u00A0(MedMera\u00A0Bank)', logo: CoopLogo, url: "https://www.coop.se/bank-och-betalkort/om-medmera-bank/", description: `MedMera Bank, en del av Kooperativa Förbundet (KF), erbjuder finansiella tjänster under Coops varumärke sedan 2007. De fokuserar på Coop Privatlån och Coop Spara, med 3,5 miljoner konsumentkooperationsmedlemmar som en viktig målgrupp.\nMed cirka 50 anställda och Kenneth Nybohm som VD, arbetar MedMera Bank nära Coop och övriga koncernen. De strävar efter att erbjuda enkla och trygga finansiella lösningar genom transparens och kunskapsdelning.\nMedMera Bank erbjuder schyssta villkor som möter kundernas behov i vardagen, med fokus på sund och hållbar låneverksamhet. Som en mindre bank kan de snabbt anpassa sig till marknadens förändringar och kundernas behov, med modern teknik och flexibla arbetsmetoder.` }
    case 'BankNorwegian':
      return { name: 'Bank\u00A0Norwegian', logo: BankNorwegianLogo, url: "https://www.banknorwegian.se/om-oss/", description: `Bank Norwegian, en filial av NOBA Bank Group AB (publ), är en digital bank som erbjuder enkla och transparenta finansiella produkter som privatlån, sparkonton, försäkringar och kreditkort. Banken strävar efter att erbjuda användarvänliga produkter utan dolda avgifter. Bank Norwegian har över 1,7 miljoner kunder i Norden, Tyskland och Spanien. Som en norsk filial av svenska NOBA Bank Group står Bank Norwegian både under den norska och svenska finansinspektionen.\nBank Norwegian använder den senaste teknologin och automatiserade processer för att förbättra kundupplevelsen. De etablerades 2007 i Oslo och har sedan dess expanderat i Norden och Europa. I november 2022 fusionerades Bank Norwegian med Nordax Bank AB.\nDe erbjuder lån utan säkerhet som kan användas för valfritt ändamål, och hela ansökningsprocessen kan genomföras online. Bank Norwegian kan även hjälpa dig att samla dyra smålån och krediter för att förbättra din ekonomi.` };
    default:
      return { name: partnerBank, logo: "", url: "", description: `` }
  }
}

export function formatPhoneNumber(raw: string) {
  const parsedNumber = parseNumber(raw);
  return 'phone' in parsedNumber ? format(parsedNumber, 'NATIONAL') : raw;
}

export function formatMaritalStatus(status: MaritalStatus) {
  return Options.maritalStatus.find(
    ({ value }) => value === status,
  )?.label;
}

export function formatChildren(children: Children) {
  switch (children) {
    case 'NoChildren':
      return 'Inga barn';
    case 'OneChild':
      return '1';
    case 'TwoChildren':
      return '2';
    case 'ThreeChildren':
      return '3';
    case 'FourChildren':
      return '4';
    case 'FiveChildren':
      return '5';
    default:
      return '6+';
  }
}

export function formatOccupationType(occupationType: OccupationTypeName) {
  return Options.occupations.find(
    ({ value }) => value === occupationType,
  )?.label;
}

export function formatAccommodationType(accommodationType: AccomodationType) {
  return Options.accommodations.find(
    ({ value }) => value === accommodationType,
  )?.label;
}

export function formatMoney(money: MoneyMinor) {
  return formatMoneyInt(money.amount / 100);
}

export function formatAmortizationPeriod(ap: AmortizationPeriod) {
  switch (ap.chronoUnit.name) {
    case 'YEARS':
      return `${ap.length} år`
    case 'MONTHS':
      if (ap.length % 12 === 0) {
        return `${ap.length / 12} år`
      } else {
        return `${ap.length} månader`
      }
  }
}

export function formatInterest(interest: number) {
  const interestString = Number(interest).toFixed(2).replaceAll(".", ",");
  return `${interestString} %`;
}

export function formatMonth(date: string) {
  const [year, month] = date.split("-");
  return `${month}/${year}`;
}

export function formatDate(isoDate: string) {
  const dateOptions = { year: "numeric", month: "long", day: "numeric" } as Intl.DateTimeFormatOptions;
  const date = new Date(isoDate);
  return date ? date.toLocaleDateString("sv-SE", dateOptions) : isoDate;
}

export function formatStatus(status: LoanApplicationStatus, moreQuotesToCome?: boolean) {
  switch (status) {
    case LoanApplicationStatus.ApplicationDataAdded:
      return "Ansökan skapad";
    case LoanApplicationStatus.CanceledByPartner:
      return "Makulerad";
    case LoanApplicationStatus.DeclinedByAllPartners:
      return "Inga erbjudanden";
    case LoanApplicationStatus.Deleted:
      return "Borttagen";
    case LoanApplicationStatus.Done:
      return "Utbetald";
    case LoanApplicationStatus.Error:
      return "Felaktig";
    case LoanApplicationStatus.Expired:
      return "Utlöpt";
    case LoanApplicationStatus.New:
      return "Ny";
    case LoanApplicationStatus.QuoteSelected:
      return "Signering";
    case LoanApplicationStatus.QuotesRejected:
      return "Erbjudande avböjt";
    case LoanApplicationStatus.RequestForQuoteSent:
      if (moreQuotesToCome) {
        return "Hämtar";
      } else {
        return "Hämtade";
      }      
  }
}

export function formatMonthlyCost(installment: MonthlyInstallment) {
  return installment.__typename == "Annuity"
    ? `${formatMoney(installment.amount)} kr / mån`
    : `${formatMoney(installment.amountFirst)} - ${formatMoney(installment.amountLast)} / mån`;
}