// extracted by mini-css-extract-plugin
export var amountInfo = "ApplicationsHistoryTab-module--amountInfo--dc262";
export var applicationInfo = "ApplicationsHistoryTab-module--applicationInfo--b17bd";
export var emptyApplications = "ApplicationsHistoryTab-module--emptyApplications--50d0d";
export var emptyApplicationsText = "ApplicationsHistoryTab-module--emptyApplicationsText--0af37";
export var group = "ApplicationsHistoryTab-module--group--35056";
export var historyRow = "ApplicationsHistoryTab-module--historyRow--18bde";
export var noBackground = "ApplicationsHistoryTab-module--noBackground--f916f";
export var row = "ApplicationsHistoryTab-module--row--768e1";
export var statusInfo = "ApplicationsHistoryTab-module--statusInfo--c5a66";
export var statusPill = "ApplicationsHistoryTab-module--statusPill--177c7";
export var submittedDate = "ApplicationsHistoryTab-module--submittedDate--d0773";
export var tabIndicator = "ApplicationsHistoryTab-module--tabIndicator--fc502";
export var updatedDate = "ApplicationsHistoryTab-module--updatedDate--34d46";