import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import appSlice, { AppState } from '@src/redux/app';
import loanApplicationSlice, {
  LoanApplicationReduxType,
} from '@src/redux/loanApplication';
import { useClient } from 'urql';
import {
  RangedLoanPredictionResult,
  LastLoanPredictionQuery,
  LastLoanPredictionQueryVariables,
  LastLoanPredictionDocument,
  Children,
} from '../../../../graphql/generated';
import { Link } from 'gatsby-plugin-intl';
import { CircularProgress, Typography } from '@mui/material';
import * as styles from './LoanPredictionResult.module.scss';
import PrimaryButton from '../../PrimaryButton/PrimaryButton';
import useIsMobile from '@src/hooks/useIsMobile.hook';
import SliderInput from '@src/components/SliderInput';
import { formatMoney } from '@src/lib/utils';
import {
  accommodations,
  occupations,
  maritalStatus as maritalStatusOptions,
} from '@src/lib/options';
import BankIdIcon from '../../../icons/2.0/BankId.svg';
import PadlockIcon from '../../../icons/2.0/Padlock.svg';
import Offering from '@icons/2.0/illustrations/Offering.svg';
import Markdown from '@src/components/markdown';
import LoanBrokerApplicationFlow from '@src/components/LoanBrokerApplication/LoanBrokerApplicationFlow';
import * as ThirdPartyTracking from '@lib/thirdPartyTracking';
import { loanPredictionAttributes } from './utils';

type LoanPredictionResultProps = {
  updateAppliedAmount: (number) => void;
  loanApplication: {
    appliedAmount: number;
  };
  app: {
    memberId: string | null;
  };
};

const LoanPredictionResult = (props: LoanPredictionResultProps) => {
  const isMobile = useIsMobile();
  const [loanApplicationFormOpen, setLoanApplicationFormOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [
    lastLoanPrediction,
    setLastLoanPrediction,
  ] = useState<RangedLoanPredictionResult | null>(null);
  const urqlClient = useClient();

  useEffect(() => {
    urqlClient
      .query<LastLoanPredictionQuery, LastLoanPredictionQueryVariables>(
        LastLoanPredictionDocument,
        {},
        {},
      )
      .toPromise()
      .then(({ data }) => {
        setLastLoanPrediction(data.lastLoanPrediction);
        setLoading(false);
      });
  }, []);

  if (isLoading) return <CircularProgress />;
  if (!lastLoanPrediction) return null;

  const {
    likelihood,
    minInterestRate,
    maxInterestRate,
  } = loanPredictionAttributes(
    lastLoanPrediction,
    props.loanApplication.appliedAmount,
  );

  const likelihoodLabel =
    likelihood > 80
      ? 'Mycket hög'
      : likelihood > 60
      ? 'Hög'
      : likelihood > 40
      ? 'Över medel'
      : likelihood > 20
      ? 'Medel'
      : 'Låg';
  const likelihoodBackgroundColor = likelihood > 20 ? '#009624' : '#F0B70E';
  const likelihoodColor = likelihood > 20 ? 'white' : 'black';

  const familyLabel: string = `${maritalStatusOptions.find(
    x => x.value === lastLoanPrediction.input.maritalStatus,
  )?.label ?? ''}; ${
    lastLoanPrediction.input.children === Children.NoChildren
      ? 'inga barn'
      : 'har barn'
  }`;

  const accommodationLabel: string =
    accommodations.find(
      x => x.value === lastLoanPrediction.input.accommodationType,
    )?.label ?? '';

  const occupationLabel: string = `${formatMoney(
    lastLoanPrediction.input.monthlyIncome,
  )} kr/mån; ${occupations
    .find(x => x.value === lastLoanPrediction.input.occupationType)
    ?.label.toLowerCase() ?? ''}`;

  const otherInfoLabel: string = `${
    lastLoanPrediction.input.paymentRemark
      ? 'Har betalningsanmärkning'
      : 'Ingen betalningsanmärkning'
  }; ${
    lastLoanPrediction.input.existingLoansTotalAmount > 0
      ? 'har tidigare privatlån'
      : 'inget tidigare privatlån'
  }`;

  return (
    <>
      <div className={styles.headingWrapper}>
        <div>
          <Typography variant="h2" style={{ marginBottom: '0.5rem' }}>
            Ränteindex
          </Typography>
        </div>
        <div>
          <Link to="/ranteindex">Uppdatera din information</Link>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div>
          <SliderInput
            label="Om du önskar samla/låna"
            value={props.loanApplication.appliedAmount}
            onChange={v => {
              props.updateAppliedAmount(parseInt(v));
            }}
            onAfterChange={() => {
              ThirdPartyTracking.loanPredictionEngagement(
                props.app.memberId,
                'update amount',
                props.loanApplication.appliedAmount,
                likelihood,
                minInterestRate,
                maxInterestRate,
              );
            }}
            min={Math.max(
              lastLoanPrediction?.input.existingLoansToConsolidate ?? 0,
              10_000,
            )}
            max={600_000}
            step={10_000}
            formatValue={v => `${formatMoney(v)} kr`}
            showMinMaxLabels
            classNameWrapper={styles.sliderWrapper}
          />
          {isMobile && (
            <div style={{ marginBottom: '0.5rem' }}>
              Så är din sannolikhet att få låneerbjudanden från våra partners{' '}
              {likelihood}%
            </div>
          )}
          <div className={styles.predictionContainer}>
            <div>
              {isMobile
                ? 'Sannolikhet:'
                : `Så är din sannolikhet att få låneerbjudanden från våra partners ${likelihood}%`}
            </div>
            <div
              style={{
                color: likelihoodColor,
                backgroundColor: likelihoodBackgroundColor,
              }}
            >
              {likelihoodLabel}
            </div>
          </div>
          <div className={styles.predictionContainer}>
            <div>
              {isMobile
                ? 'Förväntad ränta:'
                : 'Du bör bli erbjuden en ränta på'}
            </div>
            <div style={{ color: 'white', backgroundColor: '#8940F7' }}>
              {`${minInterestRate}% - ${maxInterestRate}%`.replaceAll('.', ',')}
            </div>
          </div>
          <div>
            Vi baserar ovanstående på dina angivna ekonomiska förutsättningar
            samt på maskininlärning utifrån tusentals faktiska låneansökningar
            som skickats till våra samarbetspartners.
          </div>
        </div>
        <div>
          <div className={styles.inputDetails}>
            <div>
              <div>Dina uppgifter</div>
              <div>
                <Link to="/ranteindex">Uppdatera</Link>
              </div>
            </div>
            <div>
              <div>Familj:</div>
              <div>{familyLabel}</div>
            </div>
            <div>
              <div>Boende:</div>
              <div>{accommodationLabel}</div>
            </div>
            <div>
              <div>Inkomster:</div>
              <div>{occupationLabel}</div>
            </div>
            <div>
              <div>Övrigt:</div>
              <div>{otherInfoLabel}</div>
            </div>
          </div>
          <div className={styles.createApplicationButtonContainer}>
            <PrimaryButton
              onClick={() => {
                setLoanApplicationFormOpen(true);
                ThirdPartyTracking.loanPredictionEngagement(
                  props.app.memberId,
                  'loan application form opened',
                  '',
                );
              }}
            >
              Förvandla till en ansökan
            </PrimaryButton>
          </div>
          <div className={styles.securedLoanApplicationSection}>
            <div>
              <PadlockIcon />
            </div>
            <div>Snabb och säker ansökan med BankID</div>
            <div>
              <BankIdIcon />
            </div>
          </div>
          <Markdown openLinksInNewTab className={styles.fineprintSection}>
            Genom att påbörja ansökan godkänner du
            [användarvillkoren](https://rocker.com/anvandarvillkor/) samt
            [Tilläggsvillkor till Rockers
            Användarvillkor](https://rocker.com/anvandarvillkor/lan/). För
            information om hur vi hanterar din data, se vår
            [personuppgiftspolicy](https://rocker.com/personuppgiftspolicy/).
          </Markdown>
        </div>
      </div>
      {loanApplicationFormOpen && (
        <LoanBrokerApplicationFlow
          closeModal={() => {
            setLoanApplicationFormOpen(false);
          }}
          submitWithBankId={false}
          initialStep="Start"
        />
      )}
    </>
  );
};

// TODO: delete this at some point
const RangeSegments = ({
  valueA,
  valueB,
  min,
  max,
  colorA,
  colorB,
  colorC,
  textColorA,
  textColorB,
  textColorC,
  titleA,
  titleB,
  titleC,
}: {
  valueA: number;
  valueB: number;
  min: number;
  max: number;
  colorA: string;
  colorB: string;
  colorC: string;
  textColorA: string;
  textColorB: string;
  textColorC: string;
  titleA: string;
  titleB: string;
  titleC: string;
}) => {
  // normalization
  const epsilon = (max - min) / 10;
  const normalizedValueA = Math.min(max, Math.max(min, valueA));
  const normalizedValueB = Math.min(max, Math.max(min, valueB));
  const formattedValueA = valueA.toLocaleString('sv-SE');
  const formattedValueB = valueB.toLocaleString('sv-SE');

  if (normalizedValueA <= min + epsilon) {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'start' }}>
          <div
            className={styles.rangeSegmentBubble}
            style={{
              backgroundColor: colorA,
              color: textColorA,
            }}
          >
            <div>
              {titleA}
              {valueA === valueB ? (
                <span className={styles.percentage}>{formattedValueA}</span>
              ) : (
                <>
                  <span className={styles.percentage}>{formattedValueA}</span> -{' '}
                  <span className={styles.percentage}>{formattedValueB}</span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.twoSegmentsContainer}>
          <div
            className={styles.leftSegment}
            style={{
              width: `${Math.max(
                (100 * (normalizedValueB - min)) / (max - min),
                5,
              )}%`,
              backgroundColor: colorA,
            }}
          ></div>
        </div>
      </>
    );
  } else if (normalizedValueB >= max - epsilon) {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <div
            className={styles.rangeSegmentBubble}
            style={{
              backgroundColor: colorC,
              color: textColorC,
            }}
          >
            {titleC}
            {valueA === valueB ? (
              <span className={styles.percentage}>{formattedValueA}</span>
            ) : (
              <>
                <span className={styles.percentage}>{formattedValueA}</span> -{' '}
                <span className={styles.percentage}>{formattedValueB}</span>
              </>
            )}
          </div>
        </div>
        <div className={styles.twoSegmentsContainer}>
          <div
            className={styles.rightSegment}
            style={{
              width: `${Math.max(
                (100 * (max - normalizedValueA)) / (max - min),
                5,
              )}%`,
              backgroundColor: colorC,
            }}
          ></div>
        </div>
      </>
    );
  } else {
    const valueRangeCenter =
      ((valueA + valueB) / 2 - Math.min(valueA, min)) /
      (Math.max(valueB, max) - Math.min(valueA, min));
    const fillColor =
      valueRangeCenter < 0.4
        ? colorA
        : valueRangeCenter > 0.6
        ? colorC
        : colorB;
    const textColor =
      valueRangeCenter < 0.4
        ? textColorA
        : valueRangeCenter > 0.6
        ? textColorC
        : textColorB;
    const title =
      valueRangeCenter < 0.4
        ? titleA
        : valueRangeCenter > 0.6
        ? titleC
        : titleB;
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className={styles.rangeSegmentBubble}
            style={{
              color: textColor,
              backgroundColor: fillColor,
            }}
          >
            {title}
            {valueA === valueB ? (
              <span className={styles.percentage}>{formattedValueA}</span>
            ) : (
              <>
                <span className={styles.percentage}>{formattedValueA}</span> -{' '}
                <span className={styles.percentage}>{formattedValueB}</span>
              </>
            )}
          </div>
        </div>
        <div className={styles.threeSegmentsContainer}>
          <div
            style={{
              width: `${Math.max(
                (100 * (normalizedValueB - normalizedValueA)) / (max - min),
                5,
              )}%`,
              left: `${(100 * (normalizedValueA - min)) / (max - min)}%`,
              backgroundColor: fillColor,
            }}
          ></div>
        </div>
      </>
    );
  }
};

const mapStateToProps = (state: {
  loanApplication: LoanApplicationReduxType;
  app: AppState;
}) => ({
  loanApplication: state.loanApplication,
  app: state.app,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      ...loanApplicationSlice.actions,
      authStarted: null,
      authCompleted: null,
      setLoggedIn: null,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanPredictionResult);
