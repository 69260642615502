import React, { CSSProperties, ReactNode } from "react";
import * as styles from './ApplicationStatusPill.module.scss';
import classNames from "classnames";


export default function ApplicationStatusPill(props: { children: ReactNode, green?: boolean, style?: CSSProperties }) {
  return <span style={props.style} className={classNames(styles.pill, props.green && styles.green)}>
    {props.children}
  </span >
}
