// extracted by mini-css-extract-plugin
export var actions = "ApplicationDetails-module--actions--a25dc";
export var applicant = "ApplicationDetails-module--applicant--e8a2e";
export var application = "ApplicationDetails-module--application--2d186";
export var confirmation = "ApplicationDetails-module--confirmation--a4397";
export var deleteButton = "ApplicationDetails-module--deleteButton--cd6ab";
export var group = "ApplicationDetails-module--group--aa863";
export var item = "ApplicationDetails-module--item--31405";
export var panes = "ApplicationDetails-module--panes--73cda";
export var root = "ApplicationDetails-module--root--e9055";
export var smallItem = "ApplicationDetails-module--smallItem--9e8ef";