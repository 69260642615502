import React from 'react';
import { formatAmortizationPeriod, formatDate, formatMoney, formatStatus } from '../util';
import ApplicationStatusPill from '../ApplicationStatusPill';
import Payment from "../../../images/app/payment_icon.svg";
import * as styles from './ApplicationsHistoryTab.module.scss';

import Row from './Row';
export default function ApplicationsHistoryTab(props: { applications: any[], isSmallScreen: boolean }) {
  return <div className={`${styles.group} ${styles.marginTop}`}>
    {props.applications.length == 0 &&
      <div className={styles.emptyApplications}>
        <p className={styles.emptyApplicationsText}>
          Här samlar vi dina tidigare ansökningar.
        </p>
      </div>}
    {props.applications.map(application => {
      const amount = application.applicationData?.totalAmount;
      const amortizationPeriod = application.applicationData?.amortizationPeriod;
      const status = application.status;
      return <Row key={application.applicationId} className={styles.historyRow}>
        <div className={styles.applicationInfo}>
          <img
            src={Payment}
            alt="icon"
          />
          <div>
            <p className={styles.amountInfo}>{amount && formatMoney(amount)} kr / {amortizationPeriod && formatAmortizationPeriod(amortizationPeriod)}</p>
            <p className={styles.submittedDate}>Inskickad {formatDate(application.createdAt)}</p>
          </div>
        </div>
        <div className={styles.statusInfo}>
          <div>
            <ApplicationStatusPill className={styles.statusPill}>{formatStatus(status)}</ApplicationStatusPill>
            {!props.isSmallScreen && <p className={styles.updatedDate}>Senast uppdaterad {formatDate(application.updatedAt)}</p>}
          </div>
        </div>
      </Row>
    })}
  </div>
}