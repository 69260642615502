import React from 'react';

import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { AppState } from '@src/redux/app';
import Container from '@src/components/Container/Container';
import * as styles from './AppLayout.module.scss';
import SEO from '@src/components/seo';
import LoginForm from '../LoginForm';
import Navbar from '../Navbar';

type LayoutProps = {
  children: React.ReactNode;
  seoData?: any;
  loggedIn: Boolean;
};

function AppLayout({ children, seoData, loggedIn }: LayoutProps) {
  return (
    <>
      <Helmet
        bodyAttributes={{ class: styles.appBody }}
        htmlAttributes={{ class: styles.appHtml }}
      />
      <SEO
        title={
          seoData && seoData.title
            ? seoData.title
            : 'Rocker - Som en bank borde fungera.'
        }
        description={
          seoData && seoData.description
            ? seoData.description
            : 'Rocker gör din vardagsekonomi enklare. Betala, spara, låna, budgetera och håll koll. När, var och hur du vill smidigt i Rocker-appen.'
        }
      />

      {loggedIn ? (
        <div>
          <Navbar />

          <main style={{ background: '#E5E5E5' }}>
            <div className={styles.wrapper}>
              <Container>
                <div className={styles.column}>{children}</div>
              </Container>
            </div>
          </main>
        </div>
      ) : (
        <main>
          <LoginForm />
        </main>
      )}
    </>
  );
}

const mapStateToProps = (state: { app: AppState }) => ({
  loggedIn: state.app.loggedIn,
});

export default connect(mapStateToProps)(AppLayout);
