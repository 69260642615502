
import AppLayout from '@src/components/app/AppLayout';
import React from 'react';
import ApplicationsOverview from '@src/components/app/ApplicationsOverview';
import { Router } from "@reach/router";
import ProductPage from '@src/components/app/ProductPage';
import ApplicationDetails from '@src/components/app/ApplicationDetails';
import QuotesOverview from '@src/components/app/QuotesOverview';

function App() {
  return <AppLayout>
    <Router basepath="/app">
      <ApplicationsOverview path="/" tab='applications' />
      <ApplicationsOverview path="/loans" tab='loans' />
      <ApplicationsOverview path="/history" tab='history' />
      <ApplicationDetails path="/loan-application" />
      <QuotesOverview path="/quotes/:partnerBank" />
      <QuotesOverview path="/quotes" />
      <ProductPage path="/card" />
      <ProductPage path="/connect" />
      <ProductPage path="/savings" />
      <ProductPage path="/pay" />
    </Router>
  </AppLayout>
}

export default App;