import React from 'react';

const Arrow = ({ color, orientation }) => {
  let degreeRotation;
  switch (orientation) {
    case 'up':
      degreeRotation = 270;
      break;
    case 'down':
      degreeRotation = 90;
      break;
    case 'left':
      degreeRotation = 180;
      break;
    case 'right':
    default:
      degreeRotation = 0;
  }
  return (
    <svg
      style={{ transform: `rotate(${degreeRotation}deg)` }}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3886 9.05118L2.00745 9.05118C1.31709 9.05118 0.757446 8.49153 0.757446 7.80118C0.757446 7.11082 1.31709 6.55118 2.00745 6.55118L13.2895 6.55118L9.24552 2.50717C8.78641 2.04806 8.78641 1.3037 9.24552 0.844588C9.70463 0.385478 10.449 0.38548 10.9081 0.844589L17.2071 7.14361C17.5976 7.53413 17.5976 8.1673 17.2071 8.55782L10.9081 14.8568C10.449 15.3159 9.70463 15.3159 9.24552 14.8568C8.78641 14.3977 8.78641 13.6534 9.24552 13.1943L13.3886 9.05118Z"
        fill={color}
      />
    </svg>
  );
};

export default Arrow;
