import { Link } from "gatsby-plugin-intl";
import React from "react";
import * as styles from "./Breadcrumbs.module.scss"

interface Props {
  segments: { name: string, path: string }[]
}

export default function Breadcrumbs({ segments }: Props) {
  return <div className={styles.breadcrumbs}>
    {segments.map(b => (
      <span key={b.path} className={styles.breadcrumbWrapper}>
        <span className={styles.breadcrumbDivider}>/</span>
        <Link to={b.path} className={styles.breadcrumb}>
          {b.name}
        </Link>
      </span>
    ))}
  </div>
}