import Greeting from '@src/components/app/Greeting';
import React, { useState } from 'react';
import * as styles from './NewUserPanel.module.scss';
import PrimaryButton from '@src/components/PrimaryButton/PrimaryButton';
import SmallCard from '@src/components/app/SmallCard';
import LoanBrokerApplicationFlow from '@src/components/LoanBrokerApplication/LoanBrokerApplicationFlow';
import Connect from '../../../images/app/Connect.svg';
import Card from '../../../images/app/Card (1) 1.svg';
import Save from '../../../images/app/Save 3.svg';
import Pay from '../../../images/app/Pay.svg';
import Loan from '../../../images/app/Loan.svg';
import useAppPackages from '@src/hooks/useAppPackages.hook';
import LoanPredictionResult from '../LoanPredictionResult';

export default function NewUserPanel() {
  const [formOpen, setFormOpen] = useState(false);
  const packageInfo = useAppPackages();

  const maxInterestMsg = `Upp till ${packageInfo.maxInterest} sparränta`;

  return (
    <>
      <Greeting customMessage="Vad kan vi hjälpa dig med idag?" />
      <LoanPredictionResult />
      <div
        className={styles.newUserContent}
        style={{
          background: 'white',
          borderRadius: '1em',
          marginBottom: '1em',
          marginTop: '2em',
        }}
      >
        <div className={styles.header}>
          <h1>Sänka räntekostnader</h1>
          <div className={styles.subtitle}>
            Samla eller omförhandla lån för lägre månadskostnad
          </div>
        </div>
        <div className={styles.image}>
          <img src={Loan} alt="Loan" />
        </div>
        <div className={styles.content}>
          <div className={styles.bullets}>
            <ul>
              <li>Låna mellan 10 000 kr - 600 000 kr</li>
              <li>Upp till 20 års återbetalningstid</li>
              <li>Svar från flera banker med endast en UC</li>
              <li>Alltid kostnadsfritt att ansöka</li>
            </ul>
          </div>
          <PrimaryButton
            className={styles.loanButton}
            onClick={() => setFormOpen(true)}
          >
            Starta ansökan
          </PrimaryButton>
        </div>
        <div className={styles.disclaimer}>
          Representativt exempel: 100 000 kr i annuitetslån med 10 års löptid,
          nominell ränta 6,95 % och 0 kr i uppläggnings-/aviavgift blir den
          effektiva räntan 7,18 %. Totalt belopp att betala: 139 020 kr.
          Månadskostnad: 1 159 kr fördelat på 120 betalningstillfällen. Rocker
          AB är kreditförmedlare och din ansökan kommer att skickas till de
          kreditgivare som bäst matchar din profil. Återbetalningstid 1-20 år.
          Räntan är rörlig och sätts individuellt. Räntespann mellan: 3,45% -
          25,95% (Uppdaterat september 2022).
        </div>
      </div>
      <div className={styles.cards}>
        <SmallCard
          icon={<img src={Connect} alt="connect" width={80} height={80} />}
          title="Få insikt i dina utgifter"
          text="Konton, kort och sparande samlat på ett ställe."
          target="/app/connect"
        />
        <SmallCard
          icon={<img src={Card} alt="card" width={80} height={80} />}
          title="Smart betalkort"
          text="Betalkort i mobilen som kan splitta, tagga och snooza köp."
          target="/app/card"
        />
        <SmallCard
          icon={<img src={Save} alt="savings" width={80} height={80} />}
          title={maxInterestMsg}
          text="Sparränta från första kronan."
          target="/app/savings"
        />
        <SmallCard
          icon={<img src={Pay} alt="pay" width={80} height={80} />}
          title="Rocker Pay"
          text="Köp och försäljning av varor. Se fakturor, hämta ut pengar."
          target="/app/pay"
        />
      </div>
      {formOpen &&
        <LoanBrokerApplicationFlow
          closeModal={() => {
            setFormOpen(false);
          }}
          submitWithBankId={false}
          initialStepIndex={1}
        />
      }      
    </>
  );
}
