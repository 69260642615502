import React from 'react';
import * as styles from './Dialog.module.scss';
import Modal from '@mui/material/Modal';
import Icon from '@src/components/Icon/Icon';

interface Props {
  open: boolean,
  onClose: () => void,
  onBack?: () => void,
  showClose?: boolean,
  showBack?: boolean,
  children: React.ReactNode
}

export default function Dialog({open, onClose, onBack, showClose, showBack, children}: Props) {
  return <Modal
    classes={styles.dialogRoot}
    open={open}
    onClose={onClose}
  >
    <div className={styles.dialog}>
      {showClose && <Icon name="Cross" className={styles.closeButton} onClick={onClose} width={14.5} height={14.5}/>}
      {showBack && <Icon name="ArrowBack" className={styles.backButton} onClick={onBack} width={17} height={15}/>}
      {children}
    </div>    
  </Modal>
}