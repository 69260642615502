import { Link } from 'gatsby-plugin-intl';
import React from 'react';
import { bankDisplayInfo, formatAmortizationPeriod, formatDate, formatMoney } from '../util';
import ApplicationStatusPill from '../ApplicationStatusPill';
import Row from './Row';
import ExternalLinkIcon from "../../../images/app/external_link_icon.svg";
import * as styles from './DisbursedLoansTab.module.scss';

export default function DisbursedLoansTab(props: { loans: any[], isSmallScreen: boolean }) {
  const pill = <ApplicationStatusPill className={styles.pill}>utbetalt</ApplicationStatusPill>
  const link = (loan) => <Link to={loan.partnerLink}><img
    className={styles.linkIcon}
    src={ExternalLinkIcon}
    alt="icon"
  /></Link>
  return <div className={`${styles.group} ${styles.marginTop}`}>
    {props.loans.length == 0 &&
      <div className={styles.emptyState}>
        <p className={styles.emptyStateText}>
          Här kommer du hitta dina utbetalda lån.
        </p>
      </div>}
    {props.loans.map(loan => {
      const bankInfo = bankDisplayInfo(loan.partner)
      return <Row key={loan.loanId} className={styles.loanRow}>
        <div className={styles.bankInfo}>
          <img
            src={bankInfo.logo}
            alt={`${bankInfo.name} icon`}
          />
          <div>
          <p className={styles.bankName}>
            {!props.isSmallScreen && "Lån hos"} {bankInfo.name}
            {props.isSmallScreen && pill}
            {!props.isSmallScreen && link(loan)}
          </p>
          <p className={styles.loanDetails}>{formatMoney(loan.amount)} kr / {formatAmortizationPeriod(loan.amortizationPeriod)}</p>
          </div>
        </div>        
        {props.isSmallScreen && link(loan)}
        {!props.isSmallScreen &&
          <div className={styles.loanStatus}>
            {pill}
            <p className={styles.updatedAt}>Senast uppdaterad {formatDate(loan.disbursedAt)}</p>
          </div>
        }
      </Row>
    })}
  </div>
}