import { Link } from "gatsby-plugin-intl";
import React, { memo } from "react";
import Row from "./Row";
import { formatMoneyText } from "@src/lib/utils";
import { LoanApplicationStatus, LoanBrokerQuote } from "../../../../graphql/generated";
import { bankDisplayInfo, formatAmortizationPeriod, formatInterest, formatMoney, formatMonthlyCost } from "../util";
import Checkbox from "../../../images/app/checkbox_icon.svg";
import Chevron from "../../../images/app/Chevron-Right.svg";
import * as styles from './QuoteRow.module.scss';

function daysUntil(dateString: string) {
  const date = new Date(dateString);
  const millisUntil = date.getTime() - new Date().getTime();
  return Math.ceil(millisUntil / (1000 * 3600 * 24));
}

const QuoteRow = (props: { quote: LoanBrokerQuote, applicationStatus: LoanApplicationStatus, isChosen: boolean, isSmallScreen: boolean }) => {
  const bankInfo = bankDisplayInfo(props.quote.partnerBank);
  const installment = props.quote.monthlyInstallment;
  const isLink = props.isChosen || props.applicationStatus === LoanApplicationStatus.RequestForQuoteSent;

  const quoteRow = <Row className={styles.quoteRow} style={{ opacity: isLink || props.isSmallScreen ? 1 : 0.5 }}>
    <div className={styles.flexContainer}>
      <img
        src={bankInfo.logo}
        alt={`${bankInfo.name} icon`}
      />
      <div>
        <p className={styles.bankName}>{bankInfo.name}</p>
        {props.isChosen &&
          <p className={styles.label}>Accepterat</p>
        }
        {!isLink &&
          <p className={styles.label}>Avböjt</p>
        }
        {isLink && !props.isChosen && props.quote.validTo &&
          <p className={styles.label}>Erbjudandet gäller i {daysUntil(props.quote.validTo)} dagar till</p>
        }
      </div>
    </div>
    {!props.isSmallScreen &&
      <> <div style={{minWidth: '33%', flex: '1'}}>
        <p className={styles.amount}>{formatMoneyText(props.quote.amount.amount / 100)}</p>
        <p className={styles.amortizationPeriod}>{formatAmortizationPeriod(props.quote.amortizationPeriod)}</p>
      </div>
        <div style={{flex: '1'}}>
          <p className={styles.interestRate}>{formatInterest(props.quote.interestRateNominal)} ränta</p>
          <p className={styles.monthlyCost}>{formatMonthlyCost(installment)}</p>
        </div>
      </>

    }
    <div style={{ marginRight: '1em', minWidth: '10%' }}>
      <div className={styles.iconContainer}>
        <div className={styles.flex1} />
          {props.isChosen &&
            <img
              width={20}
              src={Checkbox}
              alt="icon"
            />
          }
          {isLink && <img
            width={10}
            src={Chevron}
            alt="icon"
          />}
        </div>
    </div>
  </Row>

  const wholeRow = <>
    {!props.isSmallScreen && quoteRow}
    {props.isSmallScreen &&
      <div className={styles.group} style={{ opacity: isLink ? 1 : 0.5 }}>
        {quoteRow}
        <Row className={styles.rowPadding}><p className={styles.rowText}>Godkänt belopp</p> <p className={styles.rowValue}>{formatMoney(props.quote.amount)} kr</p></Row>
        <Row className={styles.rowPadding}><p className={styles.rowText}>Återbetalningstid</p> <p className={styles.rowValue}>{formatAmortizationPeriod(props.quote.amortizationPeriod)}</p></Row>
        <Row className={styles.rowPadding}><p className={styles.rowText}>Ränta</p> <p className={styles.rowValue}>{formatInterest(props.quote.interestRateNominal)}</p></Row>
        <Row className={styles.rowPadding}><p className={styles.rowText}>Månadskostnad</p> <p className={styles.rowValue}>{formatMonthlyCost(props.quote.monthlyInstallment)}</p></Row>
      </div>
    }
  </>

  return isLink ? 
    <Link to={`/app/quotes/${props.quote.partnerBank}`} key={props.quote.partnerBank}>{wholeRow}</Link> :
    wholeRow;
}

export default memo(QuoteRow);