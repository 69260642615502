import React from 'react';
import * as styles from './Navbar.module.scss';
import useOnScroll from '@src/components/Navbar/useOnScroll.hook';
import useIsMobile from '@src/hooks/useIsMobile.hook';
import classNames from 'classnames';
import { Link, navigate } from 'gatsby';
import Logo from '@src/components/Logo';
import PrimaryButton from '@src/components/PrimaryButton';
import { Dispatch } from 'redux';
import appSlice from '@src/redux/app';
import { connect } from 'react-redux';

interface Props {
  setLoggedOut: () => void
}

function Navbar({setLoggedOut}: Props) {
  const scrollY = useOnScroll();

  const sticky = scrollY > 0;
  const isMobile = useIsMobile();


  const classes = classNames({
    [styles.navbar]: true,
    [styles.sticky]: sticky,
    [styles.opaque]: false,
    [styles.mobile]: isMobile,
  });

  return (
    <div className={styles.wrapper}>
      <nav className={classes}>
        <div className={styles.navbarContent}>
          <Link className={styles.logo} to="/" aria-label="Till förstasidan">
            <Logo type="primary" color="purple" height={24} width={121} />
          </Link>
          <div className={styles.navbarLinks}>
            <Link to="/hjalp">Hjälp?</Link>
            <PrimaryButton
              className={styles.logInButton}
              onClick={(event) => {
                event.preventDefault();
                localStorage.removeItem('token');
                setLoggedOut();
                navigate("/");
              }}
            >Logga ut</PrimaryButton>
          </div>
        </div>
      </nav>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setLoggedOut: () => dispatch(appSlice.actions.setLoggedIn(false))
  };
};

export default connect(null, mapDispatchToProps)(Navbar);