import Greeting from '@src/components/app/Greeting';
import React from 'react';
import DownloadPanel from '@src/components/app/DownloadPanel';
import Breadcrumbs from '@src/components/app/Breadcrumbs';
import { RouteComponentProps } from "@reach/router"

interface Props extends RouteComponentProps {

}

export default function CardPage({ path }: Props) {
  let breadcrumbLabel = "";
  switch(path) {
    case "/card":
      breadcrumbLabel = "Smart betalkort";
      break;
    case "/connect":
      breadcrumbLabel = "Rocker Connect";
      break;
    case "/savings":
      breadcrumbLabel = "Spara";
      break;
    case "/pay":
      breadcrumbLabel = "Rocker Pay";
      break;
  }

  return <>
    <Breadcrumbs segments={[
      { path: '/app', name: 'Hem' },
      { path: `/app${path}`, name: breadcrumbLabel }
    ]} />
    <Greeting customMessage="Vad kan vi hjälpa dig med idag?" />
    <DownloadPanel />
  </>
}