import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import * as styles from './ApplicationsOverview.module.scss';
import SmallCard from '../SmallCard';
import StartNewApplicationTab from './StartNewApplicationTab';
import ActiveApplicationTab from './ActiveApplicationTab';
import { DeclinedApplicationTab } from './DeclinedApplicationTab';
import DisbursedLoansTab from './DisbursedLoansTab';
import ApplicationsHistoryTab from './ApplicationsHistoryTab';
import {
  LoanApplicationStatus,
  LoanBrokerApplicationResponse,
  useLoanBrokerApplicationDetailsQuery,
  useLoanBrokerApplicationStateQuery,
  useLoanBrokerQuoteDecisionQuery,
  useLoanBrokerQuotesQuery,
  usePartnerLoanQuery,
} from '../../../../graphql/generated';
import { RouteComponentProps } from '@reach/router';
import NewUserPanel from '../NewUserPanel';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'gatsby';
import Gather from '../../../images/app/Gather 1.svg';
import Card from '../../../images/app/Card (1) 1.svg';
import Save from '../../../images/app/Save 3.svg';
import Greeting from '../Greeting';
import useAppPackages from '@src/hooks/useAppPackages.hook';
import { OperationContext, UseQueryState } from 'urql';
import LoanPredictionResult from '../LoanPredictionResult';

export function within90DaysInThePast(dateString: string) {
  const date = new Date(dateString);
  const millisDiff = new Date().getTime() - date.getTime();
  const daysDiff = Math.ceil(millisDiff / (1000 * 3600 * 24));
  return daysDiff <= 90;
}

export function lastApplicationDeclinedWithin90Days(
  applications: LoanBrokerApplicationResponse[],
) {
  let application = applications[0];
  if (
    application &&
    application.status == LoanApplicationStatus.DeclinedByAllPartners &&
    within90DaysInThePast(application.updatedAt)
  ) {
    return application;
  } else {
    return null;
  }
}

function useRefreshingEffect(
  result: UseQueryState,
  reexecuteQuery: (opts?: Partial<OperationContext>) => void,
  delayInSec: number,
) {
  useEffect(() => {
    if (result.fetching) {
      return;
    }
    const timerId = setTimeout(() => {
      reexecuteQuery({ requestPolicy: 'network-only' });
    }, delayInSec * 1000);

    return () => clearTimeout(timerId);
  }, [result.fetching, reexecuteQuery]);
}

const overviewTabs = ['applications', 'loans', 'history'] as const;
type OverviewTab = typeof overviewTabs[number];

function ApplicationsOverview(
  props: { tab?: OverviewTab } & RouteComponentProps,
) {
  const [
    activeApplicationResponse,
    getActiveApplication,
  ] = useLoanBrokerApplicationDetailsQuery({
    variables: { includeCompleted: false },
  });
  const [
    allApplicationsResponse,
    getAllApplications,
  ] = useLoanBrokerApplicationDetailsQuery({
    variables: { includeCompleted: true },
  });
  const [partnerLoanResponse, getPartnerLoan] = usePartnerLoanQuery();
  const activeApplication =
    activeApplicationResponse.data?.loanBrokerApplications[0];

  const [quotesResponse, getQuotes] = useLoanBrokerQuotesQuery({
    variables: { id: activeApplication?.applicationId },
  });
  const [
    quoteDecisionResponse,
    getQuoteDecision,
  ] = useLoanBrokerQuoteDecisionQuery({
    variables: { id: activeApplication?.applicationId },
  });
  const quoteDecision = quoteDecisionResponse?.data?.loanBrokerQuoteDecision;

  const [applicationStateResponse, getApplicationState] = useLoanBrokerApplicationStateQuery({
    variables: { id: activeApplication?.applicationId },
  });
  const moreQuotesToCome = !applicationStateResponse?.data?.loanBrokerState?.done;

  const pastApplications = (
    allApplicationsResponse.data?.loanBrokerApplications || []
  ).filter(
    application =>
      application.applicationId !== activeApplication?.applicationId,
  );
  const loans = partnerLoanResponse.data?.partnerLoan || [];
  const quotes = quotesResponse.data?.loanBrokerQuotes;
  const applicationDeclinedWithin90Days = lastApplicationDeclinedWithin90Days(
    pastApplications,
  );

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });
  const packageInfo = useAppPackages();

  useRefreshingEffect(activeApplicationResponse, getActiveApplication, 5);
  useRefreshingEffect(allApplicationsResponse, getAllApplications, 5);
  useRefreshingEffect(partnerLoanResponse, getPartnerLoan, 10);
  useRefreshingEffect(quotesResponse, getQuotes, 5);
  useRefreshingEffect(quotesResponse, getApplicationState, 5);
  useRefreshingEffect(quoteDecisionResponse, getQuoteDecision, 5);

  if (
    allApplicationsResponse.data &&
    allApplicationsResponse.data.loanBrokerApplications.length === 0 &&
    partnerLoanResponse.data &&
    loans.length === 0
  ) {
    return <NewUserPanel />;
  }

  let tab: OverviewTab = props.tab || 'applications';

  const maxInterestMsg = `Upp till ${packageInfo.maxInterest} sparränta`;

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Greeting />
        {!activeApplication && <LoanPredictionResult />}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tab || 'applications'}
            style={{ background: 'unset' }}
            classes={{
              flexContainer: styles.noBackground,
              indicator: styles.tabIndicator,
            }}
          >
            <Tab
              key="applications"
              value="applications"
              label="Ansökningar"
              style={{ background: 'unset' }}
              component={Link}
              to="/app"
            />
            <Tab
              key="loans"
              value="loans"
              label="Utbetalda lån"
              style={{ background: 'unset' }}
              component={Link}
              to="/app/loans"
            />
            <Tab
              key="history"
              value="history"
              label="Historik"
              style={{ background: 'unset' }}
              component={Link}
              to="/app/history"
            />
          </Tabs>
        </Box>
        {(!tab || tab === 'applications') && activeApplication && (
          <ActiveApplicationTab
            application={activeApplication}
            quotes={quotes?.quotes || []}
            quoteDecision={quoteDecision}
            moreQuotesToCome={moreQuotesToCome}
          />
        )}
        {(!tab || tab === 'applications') &&
          !activeApplication &&
          applicationDeclinedWithin90Days && (
            <DeclinedApplicationTab
              application={applicationDeclinedWithin90Days}
            />
          )}
        {(!tab || tab === 'applications') &&
          !activeApplication &&
          !applicationDeclinedWithin90Days && (
            <StartNewApplicationTab isSmallScreen={isSmallScreen} />
          )}
        {tab === 'loans' && (
          <DisbursedLoansTab loans={loans} isSmallScreen={isSmallScreen} />
        )}
        {tab === 'history' && (
          <ApplicationsHistoryTab
            applications={pastApplications}
            isSmallScreen={isSmallScreen}
          />
        )}
      </Box>

      <h3 style={{ marginTop: '76px' }}>Mer från Rocker</h3>
      <div
        style={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          gap: '2em',
        }}
      >
        <SmallCard
          icon={<img src={Gather} alt="Kontokredit" width={80} height={80} />}
          target="/kredit"
          title="Kontokredit"
          text="Gör dig redo för ett större köp och skaffa Rocker Extra. En flexibel kontokredit."
        />
        <SmallCard
          icon={<img src={Card} alt="Smart betalkort" width={80} height={80} />}
          target="/app/card"
          title="Smart betalkort"
          text="Få koll på din ekonomi i appen. Koppla dina konton och se vart du spenderar dina pengar."
        />
        <SmallCard
          icon={<img src={Save} alt="savings" width={80} height={80} />}
          target="/app/savings"
          title={maxInterestMsg}
          text="Sparränta från första kronan."
        />
      </div>
    </>
  );
}

export default ApplicationsOverview;
